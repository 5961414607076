import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './App.css';
import DetailedPage from "./component/sharedThinkly/detailedPage/index";
import UserProfile from "./component/sharedThinkly/userProfile/index";
import Login from "./component/thinklyLite/loginPage/login";
import ThinkltyLiteHome from "./component/thinklyLite/homePage/index";
import LoginSuccess from "./component/thinklyLite/loginPage/loginSuccess";
import UserCheck from "./component/thinklyLite/homePage/checkUser";
import ErrorPage from "./component/thinklyLite/loginPage/error";
import UserAuth from "./component/thinklyLite/homePage/userAuth";
import CreateAccount from "./component/thinklyLite/loginPage/createAccount";
import PaymentSuccess from "./component/sharedThinkly/userProfile/paymentSuccess";
import PaymentFailed from "./component/sharedThinkly/userProfile/paymentFailed";
import PublicationPaymentSuccess from "./component/sharedThinkly/publicationProfile/publicationPaymentSuccess"
import PublicationPaymentFailed from './component/sharedThinkly/publicationProfile/publicationPaymentFailed'
import CreateProfileShare from "./component/thinklyLite/loginPage/createProfileShare";
require('dotenv').config()


function App() {
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/signup" component={SignUp} /> */}
        <Route exact path="/" component={Login} />
        <Route exact path="/signIn" component={Login} />
        <Route exact path="/signInSuccess" component={LoginSuccess} />
        <Route exact path="/UserAuth" component={UserAuth} />
        <Route exact path="/checkUser" component={UserCheck} />
        <Route exact path="/complete-your-profile" component={CreateAccount} />
        <Route exact path="/thinkly-dashboard" component={ThinkltyLiteHome} />
        <Route exact path='/account/Created' component={CreateProfileShare} />
        <Route exact path="/error" component={ErrorPage} />
        <Route exact path="/payment/success" component={PaymentSuccess} />
        <Route exact path="/payment/failed" component={PaymentFailed} />
        <Route exact path="/subscription/success" component={PublicationPaymentSuccess} />
        <Route exact path="/subscription/failed" component={PublicationPaymentFailed} />
        <Route exact path="/:penName" component={UserProfile} />
        <Route exact path="/thinkly/:title/:ID" component={DetailedPage} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
