import React, { useEffect, useState } from 'react'
import { Avatar, ListItemText } from "@material-ui/core";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import { useHistory } from "react-router-dom";
import Play from '../images/play-video.svg';
import { Carousel } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import Right from './right';
import Left from './left';

const DetailedPart = (props) => {
    const history = useHistory();
    const [GetSummaryDetails, setSummaryDetails] = useState();
    const [getVedio, setVedio] = useState(false);
    const [getDateTime, setDateTime] = useState(0)
    const [content, setcontent] = useState();

    useEffect(() => {
        if (props.SummaryDetails !== undefined && props.SummaryDetails !== null) {
            setSummaryDetails(props.SummaryDetails);
        }
    }, []);

    useEffect(() => {
        if (GetSummaryDetails !== undefined && GetSummaryDetails !== null) {
            var date1 = new Date(GetSummaryDetails.postData.postDateTime); //post date & time
            var date2 = new Date; //current date & time
            var Difference_In_Time = date2.getTime() - date1.getTime();  //substraction of current date to post date
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);  // number of days in long flloat
            const post_Date_Time = parseFloat(Difference_In_Days).toFixed(2);  //fixed decimal to 2
            if (Math.floor(post_Date_Time) < 1) {
                const diff = Difference_In_Time / 1000;
                const difference = diff / (60 * 60);
                const hours = Math.abs(Math.round(difference))
                setDateTime(hours + ' hours ago')
            } else {
                const days = Math.floor(post_Date_Time);
                setDateTime(days + ' days ago')
            }
            // var thinklyContent = GetSummaryDetails.postData.postDescription
            // var finalContent = thinklyContent.replaceAll("<br>", "<br />")
            // console.log("finanl content", finalContent);
            // setcontent(finalContent)
        }
    }, [GetSummaryDetails]);


    return (
        <div>
            {GetSummaryDetails !== undefined && GetSummaryDetails !== null ? <>
                <div className="row body-img">
                    {getVedio === false ? <>
                        {GetSummaryDetails.postData.postImages !== undefined && GetSummaryDetails.postData.postImages.length === 0 ? ''
                            : GetSummaryDetails.postData.postImages !== undefined && GetSummaryDetails.postData.postImages.length === 1 ?
                                <img src={GetSummaryDetails.postData.postImages[0].charAt(0) === '@' ? GetSummaryDetails.postData.postImages[0].substring(1) : GetSummaryDetails.postData.postImages[0]} alt="detailed poster" />
                                : GetSummaryDetails.postData.postImages !== undefined && GetSummaryDetails.postData.postImages.length > 1 &&
                                <Carousel controls={false} interval={2000}>
                                    {GetSummaryDetails.postData.postImages.map((obj) => (
                                        <Carousel.Item>
                                            <img src={obj.charAt(0) === '@' ? obj.substring(1) : obj} alt="slider" />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                        }
                        {GetSummaryDetails.postData.videoURL !== undefined && GetSummaryDetails.postData.videoURL !== null && GetSummaryDetails.postData.videoURL !== "" &&
                            <div className="video-icon-align" onClick={() => setVedio(true)}>
                                <img src={Play} className="video-icon" style={{ width: '60px', height: '60px' }} />
                            </div>
                        }
                    </> : <iframe width="640" height="350" src={GetSummaryDetails.postData.videoURL.replace('https://youtu.be/', 'http://www.youtube.com/embed/') + '?autoplay=1&mute=1'}></iframe>}
                </div>
                {GetSummaryDetails.postData.audioURL !== undefined && GetSummaryDetails.postData.audioURL !== null && GetSummaryDetails.postData.audioURL !== "" &&
                    <div className="row mt-4 spotify-resize" style={isMobile ? { marginLeft: '0px' } : {}} >
                        <iframe src={GetSummaryDetails.postData.postOembedUrl} style={{ marginBottom: '-50px' }} width="100%" height="200" frameborder="0" allowtransparency="true" allow="encrypted-media"></iframe>
                    </div>
                }
                <div className="row body-content-align">
                    <span className="fw-bold fs-20 ff-lora"> {GetSummaryDetails.postData.postTitle} </span>
                </div>
                <div className="row body-content-align" style={{ marginTop: '-2px' }}>
                    <span className="fw-mid fc-link fs-12"> {GetSummaryDetails.postData.subcategoryname.replaceAll(',', ' | ')} </span>
                </div>
                <div className="row body-content-align mt-4" style={{ cursor: 'pointer' }} onClick={() => history.push('UserProfile')}>
                    {GetSummaryDetails.authorData.authorProfileImage !== undefined ?
                        <img src={GetSummaryDetails.authorData.authorProfileImage.charAt(0) === '@' ? GetSummaryDetails.authorData.authorProfileImage.substring(1) : GetSummaryDetails.authorData.authorProfileImage} alt="profile" style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                        : <Avatar src={<AssignmentIndOutlinedIcon />} />
                    }
                    <ListItemText style={{ marginTop: '6px', marginLeft: '10px' }}
                        primary={<div className="header-font" style={{ lineHeight: '15px' }} >
                            <span className='fs-15 fw-mid-bold'>
                                {GetSummaryDetails.authorData.authorPenName.charAt(0) === '@' ? GetSummaryDetails.authorData.authorPenName.substring(1) : GetSummaryDetails.authorData.authorPenName}
                            </span>
                        </div>}
                        secondary={<div className="row" style={{ marginLeft: '0px' }}>
                            <span className="fs-12">{getDateTime}</span>
                        </div>}
                    />
                </div>
                <div className="row mt-2 body-content-align fs-15" dangerouslySetInnerHTML={{ __html: GetSummaryDetails.postData.postDescription }} />
                {/* <Right activeDownloadPannel={() => props.activeDownloadPannel()} jsonData={GetSummaryDetails.postData} /> */}
                <hr />
                <Left activeDownloadPannel={() => props.activeDownloadPannel()} SummaryDetails={GetSummaryDetails} />
            </> : 'No data'}
        </div>
    )
}

export default DetailedPart;
