import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { Avatar, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core'
import { Link, PeopleAltOutlined, Edit, EventAvailableOutlined, EmailOutlined } from '@material-ui/icons';


const Collabrate = (props) => {
    const [state, setstate] = useState(false)

    useEffect(() => {
        if (props.show !== undefined) {
            setstate(props.show)
        }
    }, [])

    useEffect(() => {
        if (state === true) {
            $('#InviteThinkliers').modal('show')
        }
    }, [state])

    return (
        <div id="InviteThinkliers" class="modal fade in" tabindex="-1" role="dialog" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content modal-background">
                    {/* <button type="button" class="close text-right pr-2" data-dismiss="modal" onClick={() => closeFunction()} >&times;</button> */}
                    <div class="modal-body" style={{ padding: '20px 80px' }}>
                        <p className='text-center mb-4' style={{ fontSize: '20px', fontWeight: 'bold' }}>Invite Co-Authors</p>
                        <div className='row'>
                            <div className='col-2'>
                                <Avatar style={{ background: '#d8e3e9', color: '#3c7493' }}>
                                    <Link />
                                </Avatar>
                            </div>
                            <div className='col-10'>
                                <p className='mt-2' style={{ fontSize: '18px', fontFamily: 'Lora' }}>Invite Via Link</p>
                            </div>
                        </div> <hr />
                        <div className='row'>
                            <div className='col-2'>
                                <Avatar />
                            </div>
                            <div className='col-10'>
                                <text className='mt-2' style={{ fontSize: '18px', fontFamily: 'Lora' }}>UserName</text>
                                <button className='button-new-publication float-right' style={{}}>Invite</button>
                            </div>
                        </div> <hr />
                        <div className='row'>
                            <div className='col-2'>
                                <Avatar />
                            </div>
                            <div className='col-10'>
                                <text className='mt-2' style={{ fontSize: '18px', fontFamily: 'Lora' }}>UserName</text>
                                <button className='button-new-publication float-right' style={{}}>Invite</button>
                            </div>
                        </div>
                        <div className='text-center mt-5'>
                            <button className='button-new-publication'>DONE</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Collabrate