import React, { useContext, useEffect, useState } from 'react'
import Axios from "axios";
import { baseUrlThinkly } from "../../apiContent/api.jsx";
import { useHistory } from "react-router-dom";
import { authenticateIsUser } from '../loginPage/auth'
import { CircularProgress } from '@material-ui/core'
import ParaByNameFromUrl from '../../common/paraByNameFromUrl'

const UserAuth = (props) => {
    const history = useHistory();
    const BASE_URL_THINKLY = useContext(baseUrlThinkly);
    const [propsDataFromSignUp, setpropsDataFromSignUp] = useState(props.location.state)

    useEffect(() => {
        async function fetchData() {
            var email = ParaByNameFromUrl('email')
            await authenticateIsUser(email)
            const status = window.sessionStorage.getItem('signInStatus')
            if (status === 'Success') {
                checkEmail(email, status)
            } else {
                history.push('/error')
            }
        }
        if (propsDataFromSignUp !== undefined && propsDataFromSignUp.providerId === "google.com") {
            checkEmail(propsDataFromSignUp.email, "Success")
        } else {
            fetchData()
        }
    }, []);


    function checkEmail(email_id, signIn_status) {
        var config = {
            method: 'POST',
            data: { EmailID: email_id, MobileNo: "" }
        }
        Axios(`${BASE_URL_THINKLY}User/CheckUser`, config)
            .then((res) => {
                history.push({
                    pathname: '/checkUser',
                    state: { data: res.data, status: signIn_status, state: propsDataFromSignUp }
                })
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (<div style={{ padding: '150px 0px', textAlign: 'center' }}>
        <CircularProgress aria-label="Loading..." />
    </div>)
}

export default UserAuth

