import React, { useContext, useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { Avatar, Card, CircularProgress, ListItemText } from '@material-ui/core'
import { baseUrl } from '../../apiContent/api'
import Axios from 'axios'
import '../css/publicationProfile.css'
import $ from 'jquery'
import { getAnalytics, logEvent } from "firebase/analytics";
import Footer from '../common/footer';
import { PublicationSubscribeEvent } from '../../../configs/facebookPixelEvents';
import { KeyboardArrowDown } from '@material-ui/icons';
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import ParaByNameFromUrl from '../../common/paraByNameFromUrl';

const meetAuthorResponsive = {
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const PublicationProfileMob = (props) => {
    const analytics = getAnalytics();
    const BASE_URL = useContext(baseUrl);
    const [PublicationDetails, setPublicationDetails] = useState()
    const { handleSubmit, formState } = useForm(); //for form submit
    const [MainAuthor, setMainAuthor] = useState() //main author detail
    const [CoAuthorList, setCoAuthorList] = useState()
    const [AuthorID, setAuthorID] = useState()
    const [PubSection, setPubSection] = useState()  //section api data store
    const [PublilcationID, setPublilcationID] = useState()
    const [PubicationThinklies, setPubicationThinklies] = useState()  //store thinklies by this publication
    const [startIndexValue, setstartIndexValue] = useState(0)  //star index of load ore thinkies in this publication
    const [endIndexValue, setendIndexValue] = useState(9)  //end index of load ore thinkies in this publication
    const [NoMoreData, setNoMoreData] = useState(false)  //if no more thinklies then hide MORE link 
    const [UserPublication, setUserPublication] = useState() //store publilcation by author
    const [startIndexPubByAuthor, setstartIndexPubByAuthor] = useState(0)  //start index of publilcation by author
    const [endIndexPubByAuthor, setendIndexPubByAuthor] = useState(6)  //end index of publilcation by author
    const [NoMorePubByAuthor, setNoMorePubByAuthor] = useState(false)  //no data of publilcation by author
    const [MorePubByInterest, setMorePubByInterest] = useState()  //fetch publlication by current publlication interest interest
    const [startIndexPubByInterest, setstartIndexPubByInterest] = useState(0)  //start index of publilcation by Interest
    const [endIndexPubByInterest, setendIndexPubByInterest] = useState(6)  //end index of publilcation by Interest
    const [NoMorePubByInterest, setNoMorePubByInterest] = useState(false)  //no data of publilcation by Interest
    const [submitLoader, setSubmitLoader] = useState(false) //loader hide and show on payment btn
    const [PublicationPrice, setPublicationPrice] = useState() // store publication price
    const [publicationPenName, setpublicationPenName] = useState() // store publication penname
    const [emailID, setemailID] = useState()

    useEffect(() => {
        if (props.publicationDetails !== undefined && props.authorID !== undefined && props.publicationID !== undefined && props.publicationPrice !== undefined) {
            setPublilcationID(props.publicationID)  //publication id
            setAuthorID(props.authorID)  //author id
            fetchSectionDetail(props.publicationID, props.authorID)
            setPublicationDetails(props.publicationDetails)  //publication detail
            setPublicationPrice(props.publicationPrice)
            const pubPenName = props.publicationDetails.penName.charAt(0) === '@' ? props.publicationDetails.penName.substring(1) : props.publicationDetails.penName
            setpublicationPenName(pubPenName)
            logEvent(analytics, 'PUB_DETAIL_PAGE', { penname: pubPenName }) //analytic log
            const mainAuthor = props.publicationDetails.publicationAuthor.find(({ authorType }) => authorType === "AUTHOR")
            setMainAuthor(mainAuthor)  //main author name 

            const OtherAuthorName = props.publicationDetails.publicationAuthor.find(({ authorType }) => authorType !== "AUTHOR")
            setCoAuthorList(OtherAuthorName)

            // fetchPublicationThinklies(props.authorID, props.publicationID)  //function call for thinklies by publication
            // fetchPubByAuthor(props.authorID, props.publicationID)   //function call for publication by author
            // const interestArray = [];
            // props.publicationDetails.interestData.filter((obj) => {
            //     interestArray.push(obj.interestID)
            // })
            // fetchYouMayLikePubs(props.authorID, props.publicationID, interestArray)  //function call for publication by same interest
        }
    }, [])

    const fetchSectionDetail = (PID, UID) => {
        var config = {
            headers: {
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": UID
            }
        };
        Axios.get(`${BASE_URL}Publication/V2/GetPublicationDetailsByID/${PID}`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    const response = res.data.responseData.publicationDetails.publicationSections
                    setPubSection(response)
                }
            })
    }

    // fetch thinklies by same publication and load more thinklies as well
    const fetchMoreThinklyByPublication = () => {
        scrollThinklies()
    }

    function scrollThinklies() {
        setstartIndexValue(endIndexValue)
        setendIndexValue(endIndexValue + 9)
        fetchPublicationThinklies(AuthorID, PublilcationID)
    }

    function fetchPublicationThinklies(author, publication) {
        var config = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": author
            },
            data: {
                "PublicationID": publication,
                "UserID": author,
                "ThinklyID": 0,
                "StartIndex": startIndexValue,
                "EndIndex": endIndexValue
            }
        };
        Axios(`${BASE_URL}Thinkly/GetPublicationThinklies`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    const newData = res.data.responseData;
                    if (PublicationDetails !== undefined) {
                        setPubicationThinklies(PubicationThinklies => [...PubicationThinklies, ...newData])
                    } else {
                        setPubicationThinklies(newData)
                    }
                    if (newData.length < 9) {
                        setNoMoreData(true)
                    }
                } else if (res.data.responseCode === '03') {
                    setNoMoreData(true)
                }
            })
            .catch((err) => {
                console.log("GetPublicationThinklies error in catch", err);
            });
    }

    // fetch more publication by same author and load more publication as well
    const fetchMorePubByAuthor = () => {
        scrollPublilcation()
    }

    function scrollPublilcation() {
        setstartIndexPubByAuthor(endIndexValue)
        setendIndexPubByAuthor(endIndexValue + 10)
        fetchPubByAuthor(AuthorID, PublilcationID)
    }

    const fetchPubByAuthor = (authorId, p_id) => {
        var config = {
            method: 'POST',
            headers: {
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorId
            },
            data: {
                "UserID": authorId,
                "PublicationID": p_id,
                "StartIndex": startIndexPubByAuthor,
                "EndIndex": endIndexPubByAuthor
            }
        };
        Axios(`${BASE_URL}User/GetUserPublications`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    const newData = res.data.responseData
                    if (UserPublication !== undefined) {
                        setUserPublication(setUserPublication => [...setUserPublication, ...newData])
                    } else {
                        setUserPublication(newData)
                    }
                    if (newData.length < 9) {
                        setNoMorePubByAuthor(true)
                    }
                } else if (res.data.responseCode === '03') {
                    setNoMorePubByAuthor(true)
                }
            })
            .catch((err) => {
                console.log("fetch more publication by author @@@@@@@@", err);
            });
    }

    //fetch you may also like more publication by same interest and load more as well
    const fetchMorePubByInterest = () => {
        scrollPublilcationByInterest()
    }
    function scrollPublilcationByInterest() {
        setstartIndexPubByInterest(endIndexValue)
        setendIndexPubByInterest(endIndexValue + 10)
        fetchYouMayLikePubs(AuthorID, PublilcationID)
    }
    const fetchYouMayLikePubs = (authorId, publicationId, interestArray) => {
        var config = {
            method: 'POST',
            headers: {
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorId
            },
            data: {
                "UserID": authorId,
                "StartIndex": startIndexPubByInterest,
                "EndIndex": endIndexPubByInterest,
                "PublicationID": publicationId,
                "Interest": interestArray
            }
        };
        Axios(`${BASE_URL}Publication/GetOtherPublicationsYouMayLike`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    const newData = res.data.responseData
                    if (MorePubByInterest !== undefined) {
                        setMorePubByInterest(MorePubByInterest => [...MorePubByInterest, ...newData])
                    } else {
                        setMorePubByInterest(newData)
                    }
                    if (newData.length < 9) {
                        setNoMorePubByInterest(true)
                    }
                } else if (res.data.responseCode === '03') {
                    setNoMorePubByInterest(true)
                }
            })
            .catch((err) => {
                console.log("fetch more publication you may like@@@@@@@@", err);
            });
    }


    //subscribe button code
    const subscribeHandle = (publicationPayType) => {
        logEvent(analytics, 'PUB_SUBSCIRBE_CLICK', { 'payType': publicationPayType })
        if (publicationPayType === 'Free') {
            $('#userContactInfo').modal('show')
        } else {
            document.getElementById("paidpublicationdiv").scrollIntoView({ behavior: 'smooth' });
        }
    }

    //RAZORPAY form submit
    const onSubmit = (freePub) => {
        setSubmitLoader(true)
        return new Promise(resolve => {
            const price = freePub === 'Free' ? 0 : parseFloat(PublicationPrice).toFixed(2); //if free then 0 price 
            const refName = ParaByNameFromUrl('referrer')
            const form = document.createElement('form');
            form.method = 'post'
            form.action = freePub === 'Free' ? process.env.REACT_APP_PAYMENT_GATEWAY_RAZORPAY_FREE : process.env.REACT_APP_PAYMENT_GATEWAY_RAZORPAY
            const data = [
                { name: 'qty', value: "1" }, //pass hardcoded 1 only
                { name: 'receiver', value: AuthorID },
                { name: 'subscribedby', value: '0' },  //pass 0 cause not matter if logged in or not
                { name: 'remarks', value: "subscribe to publication" },
                { name: 'channel', value: "WEBAPP" },
                { name: 'amount', value: price },
                { name: 'producttype', value: "PUBLICATIONSUBSCRIPTION" },
                { name: 'publicationid', value: PublilcationID },
                { name: 'subscriptiontype', value: freePub === 'Free' ? "FREE" : "PAID" }, //free or paid
                { name: 'publicationpenname', value: publicationPenName },
                { name: 'emailid', value: freePub === 'Free' ? emailID : '' }, //for free only
                { name: 'referrer', value: refName !== undefined ? refName : '' }
            ]
            for (let x = 0; x < data.length; x++) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'text';
                hiddenField.name = data[x].name;
                hiddenField.value = data[x].value;
                hiddenField.style.display = 'none'
                form.appendChild(hiddenField);
            }
            logEvent(analytics, 'PUB_PAYMENT_GATEWAY', { payToPub: freePub === 'Free' ? '' : PublilcationID })
            document.body.appendChild(form);
            if (process.env.REACT_APP_GOOGLE_PIXEL_EVENT === 'YES') {
                PublicationSubscribeEvent()  //gooogle pixel event
            }
            form.submit();
            resolve();
        });
    }

    const handlePubView = (pubPenName) => {
        const name = pubPenName.charAt(0) === '@' ? pubPenName.substring(1) : pubPenName;
        logEvent(analytics, 'PUB_AUTHOR_PUBLICATION_CLICK', { pubPenName: pubPenName })
        var newWindow = window.open(`${name}`, '_blank')
        newWindow.penName = name
    }

    const handlePostView = (oldUrl, postID) => {
        logEvent(analytics, 'PUB_POST_CLICK', { thinklyID: postID })
        window.open(oldUrl, '_blank')
    }

    const handleUserProfile = (authorID) => {
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorID
            },
        };
        Axios.get(`${BASE_URL}User/GetUserProfileByID/${authorID}`, config)
            .then((res) => {
                logEvent(analytics, 'PUB_ATHOUR_CLICK', { authorID: authorID })
                if (res.data.responseCode === '00') {
                    const response = res.data.responseData.profileDetails
                    const url = response.profileUrl.charAt(0) === '@' ? response.profileUrl.substring(1) : response.profileUrl
                    window.open(url, '_blank')
                }
            })
            .catch((err) => {
                console.log("GetUserProfileByID error in catch", err);
            });
    }

    return (<>
        {PublicationDetails !== undefined ? <>
            <div className='container' style={{ marginTop: '4rem', marginBottom: '4rem' }}>
                <div className='row'>
                    <img src={PublicationDetails.publicationImage.charAt(0) === '@' ? PublicationDetails.publicationImage.substring(1) : PublicationDetails.publicationImage} alt='publication profile' style={{ width: '100%', objectFit: 'cover', objectPosition: 'center' }} />
                </div>
                <div className='row mt-4 text-center'>
                    <div className='col-12'>
                        <h2 className='ff-lora fs-36 fw-bold'>{PublicationDetails.publicationName}</h2>
                        {PublicationDetails.publicationAuthor.length > 2 ? <p className='fs-26'> Authored by <b>{MainAuthor.authorName}</b> and {PublicationDetails.publicationAuthor.length - 1} others</p>
                            : PublicationDetails.publicationAuthor.length === 2 ? <p className='fs-26'> Authored by <b>{MainAuthor.authorName}</b> and <b>{CoAuthorList.authorName}</b> </p>
                                : <p className='fs-26'> Authored by <b>{MainAuthor.authorName}</b> </p>}
                    </div>
                </div>
                <div className='vertical-line my-4'></div>
                {/* description */}
                {PublicationDetails.description !== undefined && <>
                    <div className='row d-flex story-content'>
                        <div className='col-12 mb-3 fs-28 fw-bold'>{PublicationDetails.about}</div>
                        <div className='col-12 text-left mb-3'>{PublicationDetails.description}</div>
                        <button className='mx-auto subscribe px-4' onClick={() => subscribeHandle(PublicationDetails.publicationPayType)}>
                            {PublicationDetails.publicationPayType === 'Paid' ? <>Subscribe Now </> : <>Subscribe for free</>}
                        </button>
                    </div>
                    <div className='vertical-line mt-3 mb-3'></div>
                </>}
                {/* for free publication get user mail ID */}
                <div id="userContactInfo" class="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <button type="button" class="close text-right pr-2" data-dismiss="modal" >&times;</button>
                            <div class="modal-body px-5 pb-4 pt-1">
                                <h5 className='text-center mb-4'>We need your emailID to send you a voucher code</h5>
                                <input type='text' placeholder='Your email ID' value={emailID} onChange={(e) => setemailID(e.target.value)} style={{ fontSize: '20px', border: 'none', outline: 'none', width: '100%' }} />
                                {<div id="infoPlease" className='error-msg'></div>}
                                <div className='text-center'>
                                    <button type='submit' className='mt-3 pointer fw-mid border-radius-4 fc-white border-none height-button fs-18 w-50 primary-bg-color' onClick={() => onSubmit('Free')} >Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* sections */}
                {PubSection !== undefined && PubSection.length > 0 && <>
                    {PubSection.map((obj, index) => {
                        return (obj.sectionTitle !== '' && obj.sectionDescription !== '' && <>
                            <div className='row d-flex' key={index}>
                                <div className='col-12'>
                                    <p className='text-center fs-28 fw-bold'>{obj.sectionTitle}</p>
                                    {obj.sectionContents !== undefined && obj.sectionContents.length > 0 && obj.sectionContents.map((data) => {
                                        return (<div className='row'>
                                            <div className='col-12'>
                                                {data.sectionImage !== null && data.sectionImage !== undefined && data.sectionImage !== ''
                                                    && <img src={data.sectionImage} alt='sectionImage' className='section-image-center' />}
                                                {data.sectionDescription !== undefined && data.sectionDescription !== null && data.sectionDescription !== ''
                                                    && <div className='' dangerouslySetInnerHTML={{ __html: data.sectionDescription }} />}
                                            </div>
                                        </div>)
                                    })}
                                </div>
                                <button className='mx-auto subscribe px-4' onClick={() => subscribeHandle(PublicationDetails.publicationPayType)}>
                                    {PublicationDetails.publicationPayType === 'Paid' ? <>Subscribe Now</> : <>Subscribe for free</>}
                                </button>
                            </div>
                            <div className='vertical-line mt-3 mb-3'></div>
                        </>)
                    })}
                </>}
                {/* thinklies in this publication */}
                {PubicationThinklies !== undefined && PubicationThinklies.length > 0 && <>
                    <p className='p-heading mb-4'>Posts in this Publication</p>
                    {PubicationThinklies.map((obj, index) => {
                        const image_url = obj.postData.postImages.length > 0 && obj.postData.postImages[0].charAt(0) === '@' ? obj.postData.postImages[0].substring(1) : obj.postData.postImages[0]
                        return (<Card className='t-in-p' key={index} onClick={() => handlePostView(obj.postData.postURL, obj.postData.postID)}>
                            <div className='row d-flex'>
                                <div className='col-4'>
                                    {obj.postData.postImages.length > 0 ? <img src={image_url} className='publilcation-image' />
                                        : <Card className='publilcation-image' style={{ background: '#faa422' }}></Card>}
                                </div>
                                <div className='col-8 pl-3 my-auto'>
                                    <h4 className='fs-22'> {obj.postData.postTitle.slice(0, 45) + (obj.postData.postTitle.length > 45 ? "..." : "")} </h4>
                                </div>
                            </div>
                        </Card>)
                    })}
                    {!NoMoreData && <p className='col-12 fs-22 fw-mid' onClick={() => fetchMoreThinklyByPublication()}> <KeyboardArrowDown /> show more</p>}
                    <div className='vertical-line mt-3 mb-3'></div>
                </>}
                {/* meet the authors */}
                {/* {PublicationDetails.publicationAuthor !== undefined && PublicationDetails.publicationAuthor.length > 0 && <div>
                    <p className='text-center fs-26 fw-bold mb-3'>Authored by</p>
                    <Carousel responsive={meetAuthorResponsive}>
                        {PublicationDetails.publicationAuthor.map((obj, index) => { //onClick={() => handleUserProfile(obj.authorID)}
                            const imageUrl = obj.authorProfileImage.charAt(0) === '@' ? obj.authorProfileImage.substring(1) : obj.authorProfileImage
                            return (<Card className="card-body" key={index}>
                                <div className='row mt-2'>
                                    <Avatar className='mx-auto ma-profile' src={imageUrl} alt="author profile" />
                                </div>
                                <div className='text-center fs-30 fw-bold'>{obj.authorName.slice(0, 16) + (obj.authorName.length > 16 ? "..." : "")}</div>
                                <p className='fs-18 text-center mb-4'>
                                    {obj.authorProfileText !== '' && (obj.authorProfileText.slice(0, 200) + (obj.authorProfileText.length > 200 ? "..." : ""))}
                                </p>
                            </Card>)
                        })}
                    </Carousel>
                </div>} */}
                {/* more publication from abc */}
                {UserPublication !== undefined && UserPublication.length > 0 && <>
                    <div className='vertical-line mt-3 mb-4'></div>
                    <p className='p-heading mb-4'>More Publications By {MainAuthor.authorName}</p>
                    {UserPublication.map((obj, index) => {
                        const imageUrl = obj.publicationImage.charAt(0) === '@' ? obj.publicationImage.substring(1) : obj.publicationImage
                        return (<div className='row mb-3 ml-1' key={index} onClick={() => handlePubView(obj.penName)}>
                            <div className='col-3'>
                                <img src={imageUrl} alt="Image" className="publilcation-image" />
                            </div>
                            <div className='col-9 pl-5'>
                                <ListItemText primary={<h5 className='fs-22 fw-bold' style={{ lineHeight: '0.6' }}>{obj.publicationName.slice(0, 18) + (obj.publicationName.length > 18 ? "..." : "")}</h5>}
                                    secondary={<p className='fs-14'>{obj.description.slice(0, 70) + (obj.description.length > 70 ? "..." : "")}</p>} />
                            </div>
                        </div>)
                    })}
                    {!NoMorePubByAuthor && <p className='col-12 text-center fs-18 fw-bold fc-primary' onClick={() => fetchMorePubByAuthor()}>+MORE</p>}
                </>}
                {/* publication profile  */}
                {PublicationDetails.publicationPayType === 'Paid' && <>
                    <div className='vertical-line my-4'></div>
                    {PublicationDetails !== undefined && PublicationDetails.publicationImage !== undefined && <div id='paidpublicationdiv'>
                        <form name="paymentGatewayrazorpay" onSubmit={handleSubmit(onSubmit)}>
                            <div className='col-12 mx-auto' >
                                <Card className="t-in-p">
                                    <div className='row mb-3'>
                                        <img className='mx-auto pay-pub-profile' src={PublicationDetails.publicationImage.charAt(0) === '@' ? PublicationDetails.publicationImage.substring(1) : PublicationDetails.publicationImage} alt="author profile" />
                                    </div>
                                    <div className='text-center mb-2'>
                                        <span className='ff-lora fs-30 fw-bold'>{PublicationDetails.publicationName}</span>
                                        <p className='fs-20 fw-mid'>{PublicationDetails.about}</p>
                                        <p className='fs-32 fw-bold'>{PublicationDetails.publicationPlan[0].planName}</p>
                                        <p className='fs-28 fw-bold mx-auto'>&#x20b9; {PublicationDetails.publicationPrice}</p>
                                    </div>
                                    <p className='text-center fs-18 fw-normal'> {PublicationDetails.publicationPlan[0].description} </p>
                                    <button type='submit' className='subscribe mx-auto px-4 d-flex' style={{ paddingTop: '0.4rem' }}>
                                        {submitLoader ? <CircularProgress style={{ width: '20px', height: '20px', color: '#fff' }} /> : <>Subscribe @ &#x20b9;{PublicationDetails.publicationPrice} {PublicationDetails.publicationPlan[0].planName}</>}
                                    </button>
                                </Card>
                            </div>
                        </form>
                    </div>}
                </>}
                {/* you may also like */}
                {MorePubByInterest !== undefined && MorePubByInterest.length > 0 && <>
                    <div className='vertical-line my-3'></div>
                    <p className='p-heading mb-4'>Other Publilcations you may like</p>
                    {MorePubByInterest.map((obj, index) => {
                        const imageUrl = obj.publicationImage.charAt(0) === '@' ? obj.publicationImage.substring(1) : obj.publicationImage
                        return (<div className='row mb-3 ml-1' key={index}>
                            <div className='col-3'>
                                <img src={imageUrl} alt="Image" className="publilcation-image" />
                            </div>
                            <div className='col-9 pl-5'>
                                <ListItemText primary={<h5 className='fs-22 fw-bold' style={{ lineHeight: '0.6' }}>{obj.publicationName.slice(0, 15) + (obj.publicationName.length > 15 ? "..." : "")}</h5>}
                                    secondary={<p className='fs-14'>{obj.description.slice(0, 60) + (obj.description.length > 60 ? "..." : "")}</p>} />
                                <h4 className='fs-14 fc-link' onClick={() => handlePubView(obj.penName)}>View the Publication</h4>
                            </div>
                        </div>)
                    })}
                    {/* {!NoMorePubByInterest && <p className='col-12 text-center fs-22 fw-bold fc-primary' onClick={() => fetchMorePubByInterest()}>+MORE</p>} */}
                </>}
            </div>
        </> : <div style={{ padding: '150px 0px', textAlign: 'center' }}>
            <CircularProgress aria-label="Loading..." />
        </div>
        }
    </>)
}

export default PublicationProfileMob

{/* testimonials */ }
{/* <div className='row d-flex'>
    <div className='col-12 text-center'>  
        <p className='p-heading'>Testimonials</p>
    </div>
    <div className='col-12 mb-4'>
        <Card className="t-in-p">
            <CardContent>
                <img src={Quote} className='mb-3' alt='double quote' />
                <p style={{ fontSize: '20px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
            </CardContent>
            <CardHeader style={{ marginTop: '-30px' }}
                avatar={<Avatar style={{ width: '50px', height: '50px' }}> R </Avatar>}
                titleTypographyProps={{ variant: 'h6' }}
                title="User name"
                subheaderTypographyProps={{ variant: 'subtitle2' }}
                subheader="About the user"
            />
        </Card>
    </div>
</div> */}
{/* <div className='vertical-line my-5'></div> */ }