import React, { useEffect, useState } from 'react'
import firebase from 'firebase/compat/app'
import FirebaseApp from './firebase' //imp don't remove
import { useHistory } from "react-router-dom";
import { register } from './auth'
import { isMobile } from 'react-device-detect'
import $ from 'jquery'
import '../css/login.css'
import '../../common/style.css'
import GmailLogo from '../Images/gmail-logo.svg'
import PlayStore from "../../sharedThinkly/images/playStore.svg";
import AppStore from "../../sharedThinkly/images/appstore.svg";
import { getAnalytics, logEvent } from "firebase/analytics";
import Header from '../../sharedThinkly/common/header';
import Footer from '../../sharedThinkly/common/footer';

const Login = (props) => {
    const analytics = getAnalytics();
    const history = useHistory();
    const emailValidate = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const [form, setForm] = useState({ email: '' })
    const [ErrorMsg, setErrorMsg] = useState('')

    useEffect(() => {
        logEvent(analytics, 'LOGIN_CLICK')
        if (process.env.REACT_APP_BASE_URL) {
            history.push("/signIn")
        }
        if (props.location.status != undefined) {
            $('#myModal').modal('show')
        }
    }, [])

    const handleSubmit = async (e) => {
        if (form.email.match(emailValidate)) {
            logEvent(analytics, 'MAGIC_LINK_MEDIUM')
            setErrorMsg()
            register(form) //auth.js
            history.push({
                pathname: '/signInSuccess',
                state: form.email
            })
        } else {
            setErrorMsg("Please provide a valid email ID")
        }
    }

    const handleGmailSignIn = () => {
        logEvent(analytics, 'GOOGLE_MEDIUM')
        const GoogleAuth = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(GoogleAuth)
            .then(function (result) {
                var user = result.user;
                const arr = user._delegate.providerData
                let obj = arr.find(o => o.providerId === 'google.com');
                window.localStorage.setItem('emailForSignIn', obj.email);
                history.push({ pathname: '/UserAuth', state: obj })
            }).catch(function (error) {
                console.log(error);
            });
    }


    return (<>
        <Header />
        <div className='container' style={{ marginTop: '7rem' }}>
            {isMobile && process.env.REACT_APP_ALLOW_SIGNUP === "NO" ? <div className='col-12 text-center py-4' style={{ marginTop: '5rem' }}>
                <p className="font-weight-bold fs-20">Download the App for a richer experience! </p>
                <p className='fs-15'>Follow your favourite publications, engage in meaningful conversations, earn & redeem Thinkly Stars for exciting rewards and many more features.</p>
                <div className="row text-center mb-3">
                    <div className="col-6 text-right">
                        <a href="https://play.google.com/store/apps/details?id=com.me.digicita.thinkly">
                            <img src={PlayStore} style={{ width: '9rem', borderRadius: '10px' }} alt="Download button for Play store" />
                        </a>
                    </div>
                    <div className="col-6 text-left">
                        <a href="https://apps.apple.com/in/app/thinkly/id1329943323">
                            <img src={AppStore} style={{ width: '8rem' }} alt="Download button for App store" />
                        </a>
                    </div>
                </div>
            </div> : <div className='text-center' style={isMobile ? { marginTop: '100px', marginBottom: '135px' } : {}}>
                <h2 className='ff-roboto fs-30 fw-bold mb-4'>Welcome back!</h2>
                <text className='fw-mid fs-20 ff-roboto'>Login with Email</text>
                <p className='fs-15 ff-roboto'>We'll send you a magic sign-in link so <br /> you don't need to remember the password</p>
                <input type="text" name='email' id='email' className='email-link mt-3 fs-18 text-center ff-roboto' placeholder='Enter your Email ID' onChange={(e) => setForm({ ...form, email: e.target.value })} required /> <br />
                <label style={{ fontSize: '12px', color: 'red' }}>{ErrorMsg !== undefined && ErrorMsg !== null && ErrorMsg}</label> <br />
                <button type='button' className='button-sign-in fw-bold border-radius-4 fc-white border-none primary-bg-color height-button fs-18 ff-roboto' onClick={() => handleSubmit()}>Send Magic Link</button> <br />
                <div className='row justify-content-center py-3'>
                    <div className='col-2'><hr /></div>
                    <div className='col-' style={{ marginTop: '3px' }}>OR</div>
                    <div className='col-2'><hr /></div>
                </div>
                <button type='button' className='button-social-link mt-2 height-button fs-18 bg-white fc-black ff-roboto border-radius-4' onClick={() => handleGmailSignIn()}>
                    <img src={GmailLogo} alt="google" className='mr-2 mb-1' /> Continue with Gmail
                </button>
            </div>}
        </div>
        <Footer />
    </>)
}

export default Login;
