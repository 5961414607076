import { Avatar } from '@material-ui/core'
import { AssignmentIndOutlined } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import Twitter from '../../thinklyLite/Images/twitter.svg'
import Facebook from '../../thinklyLite/Images/facebook.svg'
import Linkend from '../../thinklyLite/Images/linkend.svg'
import Whatsapp from '../../thinklyLite/Images/whatsapp.svg'
import { isMobile } from 'react-device-detect'
import ParaByNameFromUrl from '../../common/paraByNameFromUrl'
import { useHistory } from 'react-router-dom'
import Axios from "axios";
import { baseUrl } from '../../apiContent/api'
import ShareLink from '../../common/shareLink';
import { getAnalytics, logEvent } from "firebase/analytics";

const PaymentSuccess = () => {
    const analytics = getAnalytics();
    const BASE_URL = useContext(baseUrl)
    const history = useHistory();
    const [getProfileDetail, setprofileDetail] = useState()
    const [getpenName, setpenName] = useState()
    const [starCount, setstarCount] = useState(0)

    useEffect(() => {
        const data = ParaByNameFromUrl('penname')
        const star = ParaByNameFromUrl('stars')
        setpenName(data)
        setstarCount(star)
        if (data !== undefined && data !== null) {
            logEvent(analytics, 'USER_STAR_PAYMENT_SUCCESS_PAGE', { penname: data })  //fb pixel log
            UserData(data)
        } else {
            history.push('/error')
        }
    }, [])

    const UserData = (penName) => {
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": 0
            },
        };
        Axios.get(`${BASE_URL}User/GetDetailsByPenName/${penName}`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    setprofileDetail(res.data.responseData.Details)
                } else if (res.data.responseCode === '03') {
                    history.push('/error')
                }
            })
            .catch((err) => {
                console.log("getUserProfileDateils error in catch", err);
            });
    }

    return (<>
        {getProfileDetail !== undefined && <div class="container text-center">
            <p className='fs-30 fw-bold'>Thank you! You've made {getpenName}'s day.</p>
            <p className='px-3 fs-20'>We  will let {getpenName} know that you have gifted them <b>{starCount} {starCount > 1 ? 'stars' : 'star'}.</b></p>
            <p className='fs-20'>Share this with the world.</p>
            <div className='row d-flex'>
                {getProfileDetail.profileDetails.profileImage !== undefined ?
                    <img className='mx-auto mt-2 mb-3' src={getProfileDetail.profileDetails.profileImage.charAt(0) === '@' ? getProfileDetail.profileDetails.profileImage.substring(1) : getProfileDetail.profileDetails.profileImage}
                        alt='profile' style={{ width: '120px', height: '120px', borderRadius: '50%', objectFit: 'none' }} />
                    : <Avatar src={<AssignmentIndOutlined />} className="mx-auto mt-2 mb-4" style={{ width: '120px', height: '120px' }} />
                }
            </div>
            <ShareLink linkUrl={getpenName} />
        </div>}
    </>)
}

export default PaymentSuccess