import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Card, CircularProgress, ListItemText } from '@material-ui/core'
import { FavoriteBorder, Star } from '@material-ui/icons'
import ShareLink from '../../common/shareLink'
import '../../common/font.css'
import RedeemModal from './redeemModal'


const myData = [
    { question: "Who is a super-fan?", ans: "A super-fan is a true believer in the creator's work. A super-fan wants to invest in the creator and would often want to share endorsement and financial support in the form of Thinkly stars." },
    { question: "How does monetization with Thinkly work?", ans: "Thinkly allows super-fans to support creators by thanking them with Thinkly stars. Stars can be bought easily using the Thinkly payment gateway and gets credited to the creator's reward store instantly." },
    { question: "How much money can super-fans support with, monthly?", ans: "While, we dont have any limit - a good idea will be to begin with whatever you are comfortable with. Most of our super-fans would typically support their favorite creators with INR 200-1000 from time to time." },
    { question: "What are the options to pay?", ans: "The Thinkly payment options are flexible, You can do a bank transfer, use your credit or debit car - or easiest of all, just simply use UPI with google pay, phonepay etc" },
    { question: "Can I support multiple creators?", ans: "Of course you can. The world of tomorrow gets better with independent creators rising and flourishing. The creator economy needs you." },
    { question: "What is the proof that the money reached the content-creator?", ans: "You will get a personalized thank you from the creator(s) , each time you thank them with Thinkly Stars." },
    { question: "Where do I reach out for support?", ans: "We are available 24/7 and across 365 days with our friendly email support. Write to us at support@thinkly.com and one of us would reach out to you asap." },
]

const DashboardPage = (props) => {
    const [getPenName, setPenName] = useState()
    const [faq, setfaq] = useState(myData);
    const [supporterData, setsupporterData] = useState()
    const [show, setShow] = useState(false); //for reedem modal
    const [authorID, setAuthorID] = useState(props.authorID)
    const [UserBalance, setUserBalance] = useState(props.UserBalance)

    useEffect(() => {
        if (props.profileJson !== undefined && props.profileJson !== null && props.supporterData !== undefined) {
            const pen_name = props.profileJson.profileDetails.penName
            const data = pen_name.charAt(0) === '@' ? pen_name.substring(1) : pen_name
            setPenName(data)
            setsupporterData(props.supporterData)    
        }
        if(props.authorID !== undefined && props.UserBalance !== undefined ){
            setAuthorID(props.authorID)
            setUserBalance(props.UserBalance)
        }
    }, [])

    const modalShow = () => {
        setShow(true); //on click show redeem modal
    }

    return (<>
        <div className='container'>
            <text className={isMobile ? 'fs-18 fw-mid' : 'fs-20 fw-mid'}>My True-fans</text> <hr />
            <div className='row mt-5'>
                <div className='col-6 mb-3'>
                    <Card className='py-2' style={{ border: 'lightgray 1px solid', borderRadius: '10px' }}>
                        <div className={isMobile ? 'fw-mid text-center fs-18' : 'fw-mid text-center fs-20'}>True-fans</div>
                        <div className={isMobile ? 'fw-bold text-center fs-20' : 'fw-bold text-center fs-24'}>{supporterData !== undefined && supporterData.TotalSupporters}</div>
                    </Card>
                </div>
                <div className='col-6'>
                    <Card className='py-2' style={{ border: 'lightgray 1px solid', borderRadius: '10px' }}>
                        <div className={isMobile ? 'fw-mid text-center fs-18' : 'fw-mid text-center fs-20'}>
                            {isMobile ? 'Earnings' : 'Gross Income'}
                            {/* <button className='ml-1 fs-15 pointer' data-toggle="modal" data-target="#myModal" onClick={() => modalShow()}>Redeem</button>
                             */}
                            <button data-toggle="modal" data-target="#redeemModal" className='border-none text-center fs-18 p-10 fc-link bg-white fw-mid-bold' onClick={() => modalShow()}>Redeem</button>

                        </div>
                        <div className={isMobile ? 'fw-bold text-center fs-20' : 'fw-bold text-center fs-24'}> <Star style={{ marginTop: '-6px' }} />
                        {/* {supporterData !== undefined && supporterData.UserBalance}  */}
                        {UserBalance !== undefined ? UserBalance : '0'}
                        </div>
                        {/* <div className={isMobile ? 'fw-bold text-center fs-20' : 'fw-bold text-center fs-24'}> <Star style={{ marginTop: '-6px' }} />0 | <span>&#8377;</span>{supporterData !== undefined && supporterData.UserBalance}</div> */}
                    </Card>
                </div>
            </div>
            
            {props.profileJson !== undefined && getPenName !== undefined ? <div className='row mt-4'>
                <div className='col-12'>
                    <Card className='text-center py-3' style={{ border: 'lightgray 1px solid', borderRadius: '10px' }}>
                        <FavoriteBorder style={{ color: '#ff8383', fontSize: '2rem' }} />
                        {/* if support > 0 then primary = "Get your fans to support you!" */}
                        {isMobile ? <ListItemText primary={<span className='fw-bold fs-20'> {supporterData !== undefined && supporterData.TotalSupporters > 0 ? "Get more support!" : "No supporters yet!"} </span>}
                            secondary={<p className='fs-18'>Share your page with the world</p>} />
                            : <ListItemText primary={<span className='fw-bold fs-20'>
                                {supporterData !== undefined && supporterData.TotalSupporters > 0 ? "Get your fans to support you!" : "You don't have any supporters yet!"}
                            </span>}
                                secondary={<p className='fs-18'>Share your page with the world</p>} />}
                        <ShareLink penName={getPenName} />
                    </Card>
                </div>
            </div> : <div style={{ padding: '150px 0px', textAlign: 'center' }}>
                <CircularProgress aria-label="Loading..." />
            </div>}
            <div className='row mt-5'>
                <div className='col-12'>
                    <p className='font-weight-bold fs-30'> FAQs</p>
                    {faq.map((obj) => {
                        return (<Card className='mb-4' style={{ boxShadow: 'none' }}>
                            <p className='fw-bold fs-20'>{obj.question}</p>
                            <p className='fs-18'>{obj.ans}</p>
                        </Card>)
                    })}
                </div>
            </div>
        </div>
        <RedeemModal authorID={authorID} UserBalance={UserBalance} showModal={show} onChangeCallback={props.onChangeCallback} onChangeCallback1={props.onChangeCallback1}/>
    </>)
}

export default DashboardPage


{/* <div className='row mt-4'>
                <div className='col-12'>
                    <div className='row'>
                        <div className='col-4'>
                            <p className='font-weight-bold'>Statistics</p>
                        </div>
                        <div className='col-8 fs-12'>
                            <span className='float-right'> 30days  90days  Date Range<ArrowDropDown /> </span>
                        </div>
                    </div>
                    <Card className='py-3 px-3'>
                        <div className='row'>
                            <div className={isMobile ? 'col-6 mb-3' : 'col-4 mb-3'}>
                                <div className='fc-gray'>Income</div>
                                <div className='fs-20 fw-bold'>0 <ArrowDownwardRounded className='fc-gray mb-1' /> </div>
                                <p className='fc-gray'>0% vs last week</p>
                            </div>
                            <div className={isMobile ? 'col-6 mb-3' : 'col-4 mb-3'}>
                                <div className='fc-gray'>Likes</div>
                                <div className='fs-20 fw-bold'>0 <ArrowDownwardRounded className='fc-gray mb-1' /> </div>
                                <p className='fc-gray'>0% vs last week</p>
                            </div>
                            <div className={isMobile ? 'col-6 mb-3' : 'col-4 mb-3'}>
                                <div className='fc-gray'>Supporters</div>
                                <div className='fs-20 fw-bold'>0 <ArrowDownwardRounded className='fc-gray mb-1' /> </div>
                                <p className='fc-gray'>0% vs last week</p>
                            </div>
                            <div className={isMobile ? 'col-6 mb-3' : 'col-4'}>
                                <div className='fc-gray'>Share</div>
                                <div className='fs-20 fw-bold'>0 <ArrowDownwardRounded className='fc-gray mb-1' /> </div>
                                <p className='fc-gray'>0% vs last week</p>
                            </div>
                            <div className={isMobile ? 'col-6 mb-3' : 'col-4'}>
                                <div className='fc-gray'>Views</div>
                                <div className='fs-20 fw-bold'>0 <ArrowUpwardRounded className='fc-gray mb-1' /> </div>
                                <p className='fc-gray'>0% vs last week</p>
                            </div>
                        </div>
                    </Card>
                </div>
            </div> */}
{/* <div className='row mt-4 mb-2'>
                <div className='col-md-6 text-center'>
                    <p className='fw-bold text-left'>Publications</p>
                    <p className={isMobile ? 'fs-14 fc-gray' : 'fs-14 fc-gray mt-5 mb-5'}>You do not have publications yet</p>
                    <button className='button-sign-in w-100'> Create </button>
                </div>
                <div className={isMobile ? 'col-md-6 mt-4' : 'col-md-6'}>
                    <p className='fw-bold'>Resources</p>
                    <Card className='w-100 text-center py-3 fw-mid'>Frequently asked questions</Card>
                    <Card className='mt-4 w-100 text-center py-3 fw-mid'>For creators</Card>
                </div>
            </div> */}