import { fetchAndActivate, getRemoteConfig, getValue } from "@firebase/remote-config";

export const RemoteConfiguration = async () => {
    const remoteConfig = getRemoteConfig();
    // remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    const isFetched = await fetchAndActivate(remoteConfig)
    if (!isFetched) {
        let data = getValue(remoteConfig, "ThinklyConfiguration")
        window.sessionStorage.setItem("ThinklyConfigData", data._value)
    }
}

export const UserTransactionsConfiguration = async () => {
    const remoteConfig = getRemoteConfig();
    const isFetched = await fetchAndActivate(remoteConfig)
    if (!isFetched) {
        let data = getValue(remoteConfig, "UserTransactionsFilterSettings1")
        return data._value;
    }
}