import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { useHistory } from 'react-router-dom'
import ShareLink from '../../common/shareLink'

const CreateProfileShare = (props) => {
  const history = useHistory();
  const [userID, setuserID] = useState(props.location.userID)
  const [profileImage, setprofileImage] = useState(props.location.profile)
  const [userName, setuserName] = useState(props.location.penName)
  const [shareUrlStatusFromApi, setshareUrlStatusFromApi] = useState(false)

  useEffect(() => {
    $('#urlSharePopUp').modal('show')
  }, [userID, userName, profileImage])


  const openDashboard = () => {
    $('#urlSharePopUp').modal('hide')
    history.push({
      pathname: '/thinkly-dashboard',
      userID: userID,
      userStatus: 'Success'
    })
  }

  return (<>
    <div className='container' style={{ paddingTop: '500px' }}> </div>
    <div class="modal fade" id="urlSharePopUp" role="dialog" data-backdrop="static" data-keyboard="false">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content"> 
          <button type="button" class="close text-right pr-2" onClick={() => openDashboard()}>&times;</button>
          <div class="modal-body text-center">
            <h2 className='fs-30 fw-bold'>Your page is live!</h2>
            <h3 className='fs-20'>Share it with the world.</h3>
            <img className='my-3' src={profileImage} alt='profile' style={{ width: '100px', height: '100px', borderRadius: '50%', objectFit: 'none' }} />
            <h2 className='fs-20 fw-bold'>{userName}</h2>

            {/* <p className='fs-18 mb-3 fc-link' id="userShareUrl"> {process.env.REACT_APP_BASE_URL + userName}</p> */}
            <ShareLink linkUrl={userName} />
          </div>
        </div>
      </div>
    </div>
  </>)
}

export default CreateProfileShare