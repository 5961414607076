import React, { useContext, useState, useEffect } from 'react'
import { Avatar, CircularProgress, ListItemText } from '@material-ui/core';
import Axios from "axios";
import $ from 'jquery'
import { baseUrlThinkly } from "../../apiContent/api.jsx";
import { DeleteForever, Edit, AssignmentIndOutlined } from '@material-ui/icons';
import NewThinkly from './newThinkly.jsx';

const MyDraft = (props) => {
  const BASE_URL_THINKLY = useContext(baseUrlThinkly)
  const [MyDrafts, setMyDrafts] = useState()
  const [authorID, setAuthorID] = useState(props.authorID)
  const [draftId, setDraftId] = useState()
  const [editDraft, setEditDraft] = useState(false)

  useEffect(() => {
    if (props.authorID !== undefined && props.thinklyConfigJSON !== undefined) {
      GetDraftsForUser(props.authorID)
      setAuthorID(props.authorID)  //state
    }
  }, [])


  const GetDraftsForUser = (authorID) => {
    var config = {
      headers: {
        "DeviceID": process.env.REACT_APP_DEVICE_ID,
        "UserID": authorID
      },
    };
    Axios.get(`${BASE_URL_THINKLY}Draft/GetDraftsForUser/${authorID}`, config)
      .then((res) => {
        if (res.data.responseCode === '00') {
          setMyDrafts(res.data.responseData.Drafts)
        } else if (res.data.responseCode === '03') {
          setMyDrafts([])
        }
      })
      .catch((err) => {
        console.log("GetDraftsForUser error in catch", err);
      });
  }

  const DeleteDraft = () => {
    var config = {
      method: 'POST',
      headers: {
        "DeviceID": process.env.REACT_APP_DEVICE_ID,
        "UserID": authorID
      },
      data: {
        DraftID: draftId,
      }
    };
    Axios(`${BASE_URL_THINKLY}draft/DeleteDraft`, config)
      .then((res) => {
        if (res.data.responseCode === '00') {
          GetDraftsForUser(authorID)
          $('#deleteDraft').modal('hide')
        }
      })
      .catch((err) => {
        console.log("Delete Draft error in catch", err);
      });
  }

  const deleteButton = (DraftID) => {
    setDraftId(DraftID)
    $('#deleteDraft').modal('show')
  }

  const editButton = (id) => {
    setDraftId(id)
    setEditDraft(true)
  }

  return (<>
    <h3>My Drafts</h3> <hr />
    {MyDrafts !== undefined && MyDrafts !== null && MyDrafts.length > 0 ? <div>
      {MyDrafts.map((obj, index) => {
        let Images = obj.ImageNames !== undefined && (obj.ImageNames).length > 0 && obj.ImageNames[0].charAt(0) === '@' ? obj.ImageNames[0].substring(1) : obj.ImageNames[0]
        return (<div className="row my-5" key={index}>
          <div className='col-1'>
            {obj.ImageNames !== undefined && (obj.ImageNames).length > 0 ? <img alt="publication Image" style={{ width: '50px', height: '50px', objectFit: 'cover' }} src={Images} />
              : <div style={{ width: '50px', height: '50px', objectFit: 'cover', borderRadius: '4px', backgroundColor: 'lightgrey' }}></div>}
          </div>
          <div class="col-8 ml-3"> <p className="fs-22 pt-1">{obj.Title}</p> </div>
          <div className="col-1 pt-1" onClick={() => deleteButton(obj.DraftID)}> <DeleteForever /> </div>
          <div className="col-1 pt-1" onClick={() => editButton(obj.DraftID)}> <Edit /> </div>
        </div>)
      })}
    </div> : MyDrafts !== undefined && MyDrafts !== null && MyDrafts.length === 0 ? <div className='row'>
      <p className='col-12 p-4 text-center fs-18'>No Record Found</p>
    </div> : <div style={{ padding: '150px 0px', textAlign: 'center' }}>
      <CircularProgress aria-label="Loading..." />
    </div>}

    {/* Delete Draft Modal */}
    <div id="deleteDraft" className="modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <button type="button" className="close text-right pr-2" data-dismiss="modal">&times;</button>
          <div className="modal-body">
            <p className='text-center fs-22 fw-bold'>Are you sure you want to delete this post from Draft?</p>
            <p className='text-center mb-5'>If you choose to Delete, you will lose all content</p>
            <div className="text-center d-flex justify-content-center">
              <button className='primary-border-button mr-4' data-dismiss="modal">Cancel</button>
              <button className='primary-bg-button' onClick={() => DeleteDraft()}>Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    {editDraft && <NewThinkly draftID={draftId} authorID={authorID} thinklyConfigJSON={props.thinklyConfigJSON} />}
  </>
  )
}

export default MyDraft