import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client';
import "./index.css";
import App from "./App";
// import Header from './component/sharedThinkly/common/header';
import Footer from './component/sharedThinkly/common/footer';
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-quill/dist/quill.snow.css';
import { isMobile } from "react-device-detect";

const display = (
  <BrowserRouter>
    <div style={{ overflowX: "hidden", backgroundColor: "#FFFFFF" }}>
      {/* <div style={isMobile ? { paddingBottom: '60px' } : { paddingBottom: '120px' }}>
        <Header />
      </div> */}
      <App />
      {isMobile ? <div style={{ marginTop: '6rem' }}>
        <Footer />
      </div> : <Footer />}
    </div>
  </BrowserRouter>
);

const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<App tab="home" />);

//the performance of your application using different metrics
//reportWebVitals performance relayer that allows you to measure and analyze
// reportWebVitals(console.log("check project performance", display));
