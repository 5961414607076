import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ParaByNameFromUrl from '../../common/paraByNameFromUrl'

const PublicationPaymentFailed = () => {
  const history = useHistory();
  const [getpenName, setpenName] = useState()

  useEffect(() => {
    const data = ParaByNameFromUrl('penname')
    setpenName(data)
  }, [])

  const okWindowClose = () => {
    history.push(`/${getpenName}`)
  }

  return (<>
    <div class="container text-center">
      <p className='fs-28 fw-bold py-3 '>Payment Cancelled or Failed !!!</p>
      <button className='mx-auto mt-4 pointer fw-mid border-radius-4 fc-white border-none height-button fs-18 primary-bg-color px-4' onClick={() => okWindowClose()}>OK</button>  
    </div>
  </>)
}

export default PublicationPaymentFailed