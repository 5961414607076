import React, {useEffect, useState} from 'react'

function ProfileDetail(props) {
    
    return (<>
        <div>
            <h1>ProfileDetail page</h1>
        </div>
    </>)
}

export default ProfileDetail

