import "firebase/compat/auth";
import firebase from 'firebase/compat/app'
import { getAnalytics } from "firebase/analytics"
require('firebase/auth')

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    // measurementId: process.env.REACT_APP_MESUREMENT_ID
};

const app = firebase.initializeApp(firebaseConfig);
export const FirebaseApp = firebase.auth();

const analytics = getAnalytics(app)