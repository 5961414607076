import React from 'react'
import Login from './login'

const ErrorPage = () => {
    return (
        <div className='text-center'>
            <h1>Sign Up Failed! Try Again</h1>
            <Login />
        </div>
    )
}

export default ErrorPage 
