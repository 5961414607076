import { createContext } from "react";

export const baseUrl = createContext(
    document.domain === "prod.thinkly.me" ? process.env.REACT_APP_BASE_URL_AZURE : process.env.REACT_APP_BASE_URL_AZURE
);

export const baseUrlThinkly = createContext(
    document.domain === "prod.thinkly.me" ? process.env.REACT_APP_BASE_URL_THINKLY : process.env.REACT_APP_BASE_URL_THINKLY
);  

export const baseUrlThinklyApi2 = createContext(
    document.domain === "prod.thinkly.me" ? process.env.REACT_APP_BASE_URL_THINKLY_API_TWO : process.env.REACT_APP_BASE_URL_THINKLY_API_TWO
); 

export const baseUrlTest = createContext(
    document.domain === "prod.thinkly.me" ? process.env.REACT_APP_BASE_URL_TEST : process.env.REACT_APP_BASE_URL_TEST
);



