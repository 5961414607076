import React, { useEffect, useState } from 'react'
import Twitter from '../thinklyLite/Images/twitter.svg'
import Facebook from '../thinklyLite/Images/facebook.svg'
import Linkend from '../thinklyLite/Images/linkend.svg'
import Whatsapp from '../thinklyLite/Images/whatsapp.svg'
import { isMobile } from 'react-device-detect'



const ShareLink = (props) => {
    const [penName, setpenName] = useState()
    const [CopyUrl, setCopyUrl] = useState(false)
    const [Path, setPath] = useState(props.penName)

    useEffect(() => {
        if (props.linkUrl !== undefined) {
            setpenName(props.linkUrl)
        }
    }, [])

    useEffect(() => {
        var windowURL = window.location.href
        setPath(penName)
        // if (windowURL.indexOf('uat.create.thinkly.me') > 0) {
        //     setPath(process.env.REACT_APP_BASE_URL + penName)
        // } else if (windowURL.indexOf('create.thinkly.me') > 0) {
        //     setPath(process.env.REACT_APP_BASE_URL + penName)
        // } else if (windowURL.indexOf('localhost') > 0) {
        //     setPath(process.env.REACT_APP_BASE_URL + penName)
        // }
    }, [penName])


    const shareFacebook = () => {
        window.open("https://www.facebook.com/sharer/sharer.php?u=" + Path, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=700');
        return false;
    }

    const shareTwitter = () => {
        window.open("http://twitter.com/share?text=&url=" + Path, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=700');
        return false;
    }

    const shareLinkedin = () => {
        window.open("https://www.linkedin.com/shareArticle?mini=true&url=" + Path, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=700');
        return false;
    }

    const shareWhatsapp = () => {
        window.open("https://api.whatsapp.com/send?text=" + Path, '',
            'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=700');
        return false;
    }

    const shareLink = () => {
        var url = document.getElementById("userShareUrl").innerHTML;
        navigator.clipboard.writeText(url).then(function () {
            setCopyUrl(true)
            setTimeout(() => {
                setCopyUrl(false)
            }, 2000);
        }, function () {
            console.log('Copy error')
        });
    }

    const handleUrlClick = () => {
        window.open(`${Path}`, '_blank')
    }

    return (<>
        <p className='fs-18 mb-3 fc-link mb-4 pointer' id="userShareUrl" onClick={() => handleUrlClick()}> {Path}</p>
        <img src={Facebook} alt="facebook" className="px-3 pointer" onClick={() => shareFacebook()} />
        <img src={Twitter} alt="twitter" className="px-3 pointer" onClick={() => shareTwitter()} />
        <img src={Linkend} alt="Linkend" className="px-3 pointer" onClick={() => shareLinkedin()} />
        <img src={Whatsapp} alt="whatsapp" className="px-3 pointer" onClick={() => shareWhatsapp()} />
        <div className='row d-flex'>
            <button type='button' onClick={() => shareLink()} className='button-sign-in mt-4 mx-auto fw-bold border-radius-4 fc-white border-none primary-bg-color height-button fs-18 ff-roboto' style={isMobile ? { width: '78%' } : { width: '50%' }} > {CopyUrl ? 'Copied' : 'Copy Link'} </button>
        </div>
    </>)
}

export default ShareLink