import React, { useContext, useState, useEffect } from 'react'
import Axios from "axios";
import $ from 'jquery'
import Profile_pic from '../images/Rectangle.png'
import { Avatar, Card, CardMedia, CardHeader, CardContent, CircularProgress } from '@material-ui/core'
import '../css/publicationProfile.css'
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
// import Quote from '../images/double-quote.svg'
import { isMobile } from 'react-device-detect'
import PublicationMob from './publicationMobDetailed'
import { baseUrl } from "../../apiContent/api.jsx";
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { getAnalytics, logEvent } from "firebase/analytics";
import Header from '../common/header'
import Footer from '../common/footer';
import { PublicationProfileEvent, PublicationSubscribeEvent } from '../../../configs/facebookPixelEvents';
import ParaByNameFromUrl from "../../common/paraByNameFromUrl";
import { KeyboardArrowDown } from '@material-ui/icons';

const responsive1 = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 1,
    },
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 1,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const responsive2 = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 2,
    },
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 2,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
    },
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const meetAuthorResponsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
    },
    desktop: {
        breakpoint: { max: 3000, min: 768 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const PublicationProfile = () => {
    const analytics = getAnalytics();
    const history = useHistory()
    const BASE_URL = useContext(baseUrl);
    const { handleSubmit, formState } = useForm(); //for form submit
    const [getPublicationID, setPublicationID] = useState() //publication ID
    const [getAuthorID, setAuthorID] = useState() //publication's authr ID
    const [userStarBalance, setUserStarBalance] = useState() //store userBalance
    const [getPublicationDetail, setPublicationDetail] = useState()  //publication detail by publication penName
    const [getMainAuthor, setMainAuthor] = useState()  //Main author of this publication
    const [getPublicationThinkly, setPublicationThinkly] = useState()  //thinklies list in this publication
    const [startIndexValue, setstartIndexValue] = useState(0)  //star index of load ore thinkies in this publication
    const [endIndexValue, setendIndexValue] = useState(9)  //end index of load ore thinkies in this publication
    const [NoMoreData, setNoMoreData] = useState(false)  //if no more thinklies then hide MORE link 
    const [getUserPublication, setUserPublication] = useState() // more publication by the author who has written this publilcation
    const [getMorePubByInterest, setMorePubByInterest] = useState()  //more publication list by this publication's interest
    const [publicationPrice, setPublicationPrice] = useState() // store publication price
    const [publicationPenName, setpublicationPenName] = useState() // store publication penname
    const [submitLoader, setSubmitLoader] = useState(false) //loader hide and show on payment btn
    const [PubSection, setPubSection] = useState()
    const [emailID, setemailID] = useState()
    const [CoAuthorList, setCoAuthorList] = useState()

    useEffect(() => {
        if (process.env.REACT_APP_GOOGLE_PIXEL_EVENT === 'YES') {
            PublicationProfileEvent()
        }
        if (window.pen_name !== undefined && window.userStatus !== undefined) {
            logEvent(analytics, 'PUB_DETAIL_PAGE', { penname: window.pen_name })
            fetchPublicationDetail(window.pen_name)
        } else {
            var pName = window.location.href
            const data = pName.endsWith('/') ? pName.substring(0, pName.length - 1) : pName;
            var name = data.substring(data.lastIndexOf("/") + 1, data.length);
            logEvent(analytics, 'PUB_DETAIL_PAGE', { penname: window.pen_name })
            fetchPublicationDetail(name)
        }
    }, [])

    function fetchPublicationDetail(name) {
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": 0
            }
        };
        Axios.get(`${BASE_URL}User/GetDetailsByPenName/${name}`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    if (res.data.responseData.Type === "Publication") {
                        const response = res.data.responseData.Details.publicationDetails;
                        setPublicationDetail(response) //publication detail stored in state
                        setUserStarBalance(res.data.responseData.Details.userDetails.userBalance)  //userBalance stored in state
                        setPublicationPrice(res.data.responseData.Details.publicationDetails.publicationPrice) // publicationPrice stored in state
                        setpublicationPenName(response.penName.charAt(0) === '@' ? response.penName.substring(1) : response.penName) //publicationPenName stored in state
                        const mainAuthorName = response.publicationAuthor.find(({ authorType }) => authorType === "AUTHOR")
                        // for main author index switch last to start
                        const arr = response.publicationAuthor
                        const indexMain = arr.findIndex(({ authorType }) => authorType === "AUTHOR")
                        const element = arr.splice(indexMain, 1)[0]
                        arr.splice(0, 0, element);
                        // 
                        setMainAuthor(mainAuthorName)
                        const OtherAuthorName = response.publicationAuthor.find(({ authorType }) => authorType !== "AUTHOR")
                        setCoAuthorList(OtherAuthorName)
                        setPublicationID(response.publicationID)
                        setAuthorID(response.createdBy) //publication's author Id stored in state
                        fetchSectionDetail(response.publicationID, response.createdBy) //function call
                        // fetchPublicationThinklies(response.publicationID, response.createdBy)  //function call
                        // fetchMoreP`ubByAuthor(response.publicationID, response.createdBy)   //function call
                        // const interestArray = [];
                        // response.interestData.filter((obj) => {
                        //     interestArray.push(obj.interestID)
                        // })
                        // fetchYouMayLikePubs(response.createdBy, interestArray)  //function call
                    } else {
                        history.push('/')
                    }
                } else if (res.data.responseCode === '03') {
                    history.push('/')
                }
            })
            .catch((err) => {
                console.log("publication detail api error in catch", err);
            });
    }

    function scrollThinklies() {
        setstartIndexValue(endIndexValue)
        setendIndexValue(endIndexValue + 9)
    }

    const fetchSectionDetail = (PID, UID) => {
        var config = {
            headers: {
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": UID
            }
        };
        Axios.get(`${BASE_URL}Publication/V2/GetPublicationDetailsByID/${PID}`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    const response = res.data.responseData.publicationDetails.publicationSections
                    setPubSection(response)
                }
            })
    }

    const fetchMoreThinklyByPublication = () => {
        scrollThinklies()
    }

    // useEffect(() => {
    //     if (getPublicationID !== undefined && getAuthorID !== undefined) {
    //         fetchPublicationThinklies(getPublicationID, getAuthorID)
    //     }
    // }, [startIndexValue, endIndexValue])

    function fetchPublicationThinklies(p_id, authorId) {
        var config = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorId
            },
            data: {
                "PublicationID": p_id,
                "UserID": authorId,
                "ThinklyID": 0,
                "StartIndex": startIndexValue,
                "EndIndex": endIndexValue
            }
        };
        Axios(`${BASE_URL}Thinkly/GetPublicationThinklies`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    const newData = res.data.responseData;
                    if (getPublicationThinkly !== undefined) {
                        setPublicationThinkly(getPublicationThinkly => [...getPublicationThinkly, ...newData])
                    } else {
                        setPublicationThinkly(newData)
                    }
                    if (newData.length < 9) {
                        setNoMoreData(true)
                    }
                } else if (res.data.responseCode === '03') {
                    setNoMoreData(true)
                }
            })
            .catch((err) => {
                console.log("GetPublicationThinklies error in catch", err);
            });
    }

    const fetchMorePubByAuthor = (p_id, authorId) => {
        var config = {
            method: 'POST',
            headers: {
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorId
            },
            data: {
                "UserID": authorId,
                "PublicationID": p_id,
                "StartIndex": 0,
                "EndIndex": 10
            }
        };
        Axios(`${BASE_URL}User/GetUserPublications`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    setUserPublication(res.data.responseData)
                }
            })
            .catch((err) => {
                console.log("fetch more publication by author @@@@@@@@", err);
            });
    }

    const fetchYouMayLikePubs = (authorId, interestArray) => {
        var config = {
            method: 'POST',
            headers: {
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorId
            },
            data: {
                "UserID": authorId,
                "StartIndex": 0,
                "EndIndex": 10,
                "PublicationID": 0,
                "Interest": interestArray
            }
        };
        Axios(`${BASE_URL}Publication/GetOtherPublicationsYouMayLike`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    setMorePubByInterest(res.data.responseData)
                }
            })
            .catch((err) => {
                console.log("fetch more publication you may like@@@@@@@@", err);
            });
    }

    const singleAuthor = (obj) => {
        const imageUrl = obj.authorProfileImage.charAt(0) === '@' ? obj.authorProfileImage.substring(1) : obj.authorProfileImage
        // onClick={() => handleUserProfile(obj.authorID)}
        return (<Card className="t-in-p">
            <div className='mb-3'>
                <Avatar className='mx-auto ma-profile' src={imageUrl} alt="author profile" />
            </div>
            <div className='text-center mb-2'>
                <p className='fs-26 fw-bold'>
                    {obj.authorName.slice(0, 18) + (obj.authorName.length > 18 ? "..." : "")}
                </p>
            </div>
            <p className='fs-18 text-center'>
                {obj.authorProfileText.length > 200 ? <> {obj.authorProfileText.slice(0, 200)} <span className='fw-mid-bold pointer' onClick={() => handleUserProfile(obj.authorID)} >...read more</span> </> : obj.authorProfileText}
            </p>
        </Card>)
    }

    const handleUserProfile = (authorID) => {
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorID
            },
        };
        Axios.get(`${BASE_URL}User/GetUserProfileByID/${authorID}`, config)
            .then((res) => {
                logEvent(analytics, 'PUB_ATHOUR_CLICK', { authorID: authorID })
                if (res.data.responseCode === '00') {
                    const response = res.data.responseData.profileDetails
                    const url = response.profileUrl.charAt(0) === '@' ? response.profileUrl.substring(1) : response.profileUrl
                    window.open(url, '_blank')
                }
            })
            .catch((err) => {
                console.log("GetUserProfileByID error in catch", err);
            });
    }

    //RAZORPAY form submit
    const onSubmit = (freePub) => {
        setSubmitLoader(true)
        return new Promise(resolve => {
            const price = freePub === 'Free' ? 0 : parseFloat(publicationPrice).toFixed(2); //if free then 0 price 
            const refName = ParaByNameFromUrl('referrer')
            const form = document.createElement('form');
            form.method = 'post'
            form.action = freePub === 'Free' ? process.env.REACT_APP_PAYMENT_GATEWAY_RAZORPAY_FREE : process.env.REACT_APP_PAYMENT_GATEWAY_RAZORPAY
            const data = [
                { name: 'qty', value: "1" }, //pass hardcoded 1 only
                { name: 'receiver', value: getAuthorID },
                { name: 'subscribedby', value: '0' },  //pass 0 cause not matter if logged in or not
                { name: 'remarks', value: "subscribe to publication" },
                { name: 'channel', value: "WEBAPP" },
                { name: 'amount', value: price },
                { name: 'producttype', value: "PUBLICATIONSUBSCRIPTION" },
                { name: 'publicationid', value: getPublicationID },
                { name: 'subscriptiontype', value: freePub === 'Free' ? "FREE" : "PAID" }, //free or paid
                { name: 'publicationpenname', value: publicationPenName },
                { name: 'emailid', value: freePub === 'Free' ? emailID : '' }, //for free only
                { name: 'referrer', value: refName !== undefined ? refName : '' }
            ]
            for (let x = 0; x < data.length; x++) {
                const hiddenField = document.createElement('input');
                hiddenField.type = 'text';
                hiddenField.name = data[x].name;
                hiddenField.value = data[x].value;
                hiddenField.style.display = 'none'
                form.appendChild(hiddenField);
            }
            logEvent(analytics, 'PUB_PAYMENT_GATEWAY', { payToPub: freePub === 'Free' ? '' : getPublicationID })
            document.body.appendChild(form);
            if (process.env.REACT_APP_GOOGLE_PIXEL_EVENT === 'YES') {
                PublicationSubscribeEvent()  //google pixel event
            }
            form.submit();
            resolve();
        });
    }

    //subscribe button code
    const subscribeHandle = (publicationPayType) => {
        logEvent(analytics, 'PUB_SUBSCIRBE_CLICK', { 'payType': publicationPayType })
        if (publicationPayType === 'Free') {
            $('#userContactInfo').modal('show')
        } else {
            document.getElementById("paidpublicationdiv").scrollIntoView({ behavior: 'smooth' });
        }
    }

    const viewpub = (penName) => {
        const name = penName.charAt(0) === '@' ? penName.substring(1) : penName;
        logEvent(analytics, 'PUB_AUTHOR_PUBLICATION_CLICK', { pubPenName: penName })
        var newWindow = window.open(`${name}`, '_blank')
        newWindow.penName = name
    }

    const handlePostView = (oldUrl, postID) => {
        logEvent(analytics, 'PUB_POST_CLICK', { thinklyID: postID })
        window.open(oldUrl, '_blank')
    }

    return (<>
        <Header />
        {getPublicationDetail !== undefined && getPublicationID !== undefined && getAuthorID !== undefined ? <>
            {isMobile ? <PublicationMob authorID={getAuthorID} publicationID={getPublicationID} publicationDetails={getPublicationDetail} publicationPrice={publicationPrice} /> : <div className='container' style={{ marginTop: '5rem' }}>
                <div className='row d-flex'>
                    <img src={getPublicationDetail.publicationImage.charAt(0) === '@' ? getPublicationDetail.publicationImage.substring(1) : getPublicationDetail.publicationImage} className='mx-auto' style={{ width: '60%', height: '25rem', objectFit: 'contain', objectPosition: 'center' }} />
                </div>
                <div className='row text-center'>
                    <div className='col-12'>
                        <span className='ff-lora fs-56 fw-bold'>{getPublicationDetail.publicationName}</span>
                        {getPublicationDetail.publicationAuthor.length > 2 ?
                            <p className='fs-28'> Authored by <b>{getMainAuthor.authorName}</b> and {getPublicationDetail.publicationAuthor.length - 1} others</p>
                            : getPublicationDetail.publicationAuthor.length === 2 ? <p className='fs-28'> Authored by <b>{getMainAuthor.authorName}</b> and <b>{CoAuthorList.authorName}</b> </p>
                                : <p className='fs-28'> Authored by <b>{getMainAuthor.authorName}</b> </p>}
                    </div>
                </div>
                <div className='vertical-line my-4'></div>
                {/* descripltion of publication */}
                {getPublicationDetail.description !== undefined && <div className='row d-flex story-content'>
                    <div className='col-12  mb-3 text-center fs-28 fw-bold'>{getPublicationDetail.about}</div>
                    <div className='col-12 text-center mb-2'>{getPublicationDetail.description}</div>
                    <button className='subscribe mx-auto mt-4 px-4' onClick={() => subscribeHandle(getPublicationDetail.publicationPayType)}>
                        {getPublicationDetail.publicationPayType === 'Paid' ? <>Subscribe Now </> : <>Subscribe for free</>}
                    </button>
                    <div className='vertical-line mt-5 mb-4'></div>
                </div>}
                {/* for free publication get user mail ID MODAL */}
                <div id="userContactInfo" class="modal fade" role="dialog" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <button type="button" class="close text-right pr-2" data-dismiss="modal" >&times;</button>
                            <div class="modal-body px-5 pb-4 pt-1">
                                <h5 className='text-center mb-4'>We need your emailID to send you a voucher code</h5>
                                <input type='text' placeholder='Your email ID' value={emailID} onChange={(e) => setemailID(e.target.value)} style={{ fontSize: '20px', border: 'none', outline: 'none', width: '100%' }} />
                                {<div id="infoPlease" className='error-msg'></div>}
                                <div className='text-center'>
                                    <button type='submit' className='mt-3 pointer fw-mid border-radius-4 fc-white border-none height-button fs-18 w-50 primary-bg-color' onClick={() => onSubmit('Free')} >Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* sections */}
                {PubSection !== undefined && PubSection.length > 0 && <>
                    {PubSection.map((obj, index) => {
                        return (<div className='row' key={index}>
                            <div className='col-12'>
                                <p className='text-center fs-28 fw-bold mb-4 mt-3'>{obj.sectionTitle}</p>
                                {obj.sectionContents !== undefined && obj.sectionContents.length <= 1 ? obj.sectionContents.map((data) => {
                                    return (<div className='row'>
                                        {(data.sectionImage !== null && data.sectionImage !== undefined && data.sectionImage !== '') && (data.sectionDescription !== undefined && data.sectionDescription !== null && data.sectionDescription !== '') ? <>
                                            <div className='col-6'>
                                                <img src={data.sectionImage} alt='sectionImage' className='section-image-center' />
                                            </div>
                                            <div className='col-6'>
                                                <div className="row ml-4 body-content-align content-font" dangerouslySetInnerHTML={{ __html: data.sectionDescription }} />
                                            </div>
                                        </> : <>
                                            {data.sectionImage !== null && data.sectionImage !== undefined && data.sectionImage !== '' && <img src={data.sectionImage} alt='sectionImage' className='section-image-center' />}
                                            {data.sectionDescription !== undefined && data.sectionDescription !== null && data.sectionDescription !== '' && <div className="row ml-4 body-content-align content-font" dangerouslySetInnerHTML={{ __html: data.sectionDescription }} />}
                                        </>}
                                    </div>)
                                }) : <div className='row'>
                                    {obj.sectionContents.map((data) => {
                                        return (<div className='col-6'>
                                            <img src={data.sectionImage} alt='sectionImage' className='section-image-center' />
                                            <div className='mx-4' dangerouslySetInnerHTML={{ __html: data.sectionDescription }} />
                                        </div>)
                                    })}
                                </div>}
                            </div>
                            <button className='subscribe mx-auto mt-4 px-4' onClick={() => subscribeHandle(getPublicationDetail.publicationPayType)}>
                                {getPublicationDetail.publicationPayType === 'Paid' ? <>Subscribe Now </> : <>Subscribe for free</>}
                            </button>
                            <div className='vertical-line mt-5 mb-4'></div>
                        </div>)
                    })}
                </>}
                {/* thinklies in this publication */}
                {getPublicationThinkly !== undefined && getPublicationThinkly.length > 0 && <>
                    <div className='row'>
                        <div className='col-12 text-center mb-2'>
                            <p className='p-heading'>Posts in this Publication</p>
                        </div>
                        {getPublicationThinkly.map((obj, index) => {
                            const image_url = obj.postData.postImages.length > 0 && obj.postData.postImages[0].charAt(0) === '@' ? obj.postData.postImages[0].substring(1) : obj.postData.postImages[0]
                            return (<div className='col-4' key={index}>
                                <Card className='t-in-p' onClick={() => handlePostView(obj.postData.postURL, obj.postData.postID)}>
                                    <div className='row d-flex'>
                                        <div className='col-2'>
                                            {obj.postData.postImages.length > 0 ? <img src={image_url} className='publilcation-image' /> : <Card className='publilcation-image' style={{ background: '#faa422' }}></Card>}
                                        </div>
                                        <div className='col-8 my-auto ml-3 fs-18'> {obj.postData.postTitle.slice(0, 44) + (obj.postData.postTitle.length > 44 ? "..." : "")} </div>
                                    </div>
                                </Card>
                            </div>)
                        })}
                    </div>
                    {!NoMoreData && <div>
                        <p className='mt-4 pointer fs-18 fw-bold' onClick={() => fetchMoreThinklyByPublication()}> <KeyboardArrowDown /> show more</p>
                    </div>}
                    <div className='vertical-line mt-5 mb-5'></div>
                </>}
                {/* meet the authors */}
                {/* <div className=''>
                    <p className='text-center fs-28 fw-bold mb-4'>Authored by</p>
                    {getPublicationDetail.publicationAuthor.length <= 1 ? <div className="container-fluid">
                        <div className="d-flex flex-row flex-nowrap">
                            {getPublicationDetail.publicationAuthor.map((obj, index) => {
                                return (<div className='col-12' key={index}>{singleAuthor(obj)}</div>)
                            })}
                        </div>
                    </div> : getPublicationDetail.publicationAuthor.length <= 2 ? <div className="container-fluid">
                        <div className="d-flex flex-row flex-nowrap">
                            {getPublicationDetail.publicationAuthor.map((obj, index) => {
                                return (<div className='col-6' key={index}>{singleAuthor(obj)}</div>)
                            })}
                        </div>
                    </div> : <Carousel responsive={meetAuthorResponsive}>
                        {getPublicationDetail.publicationAuthor.map((obj, index) => {
                            return (<div key={index}> {singleAuthor(obj)} </div>)
                        })}
                    </Carousel>}
                </div> */}
                {/* more publication from abc */}
                {getUserPublication !== undefined && getUserPublication.length > 0 && <div responsive={responsive} >
                    <div className='vertical-line my-4'></div>
                    <div className='row d-flex mb-3'>
                        <p className='mx-auto p-heading'>More Publications by {getMainAuthor.authorName}</p>
                    </div>
                    <Carousel responsive={getUserPublication.length === 1 ? responsive1 : getUserPublication.length === 2 ? responsive2 : responsive} >
                        {getUserPublication.map((obj, index) => {
                            const imageUrl = obj.publicationImage.charAt(0) === '@' ? obj.publicationImage.substring(1) : obj.publicationImage
                            return (<Card className="mb-4 morePub-card" key={index} onClick={() => viewpub(obj.penName)}>
                                <CardMedia component="img" height="250" image={imageUrl} alt="publication profile" className='morePub-cardmedia' />
                                <div className='px-2 pb-2'>
                                    <h6 className='fs-24 fw-bold'>{obj.publicationName.slice(0, 18) + (obj.publicationName.length > 18 ? "..." : "")}</h6>
                                    <p className='fs-14'> {obj.description.slice(0, 50) + (obj.description.length > 50 ? "..." : "")} </p>
                                </div>
                            </Card>)
                        })}
                    </Carousel>
                </div>}
                {/* publication pay section  */}
                {getPublicationDetail.publicationPayType === 'Paid' && <>
                    <div className='vertical-line mb-5 mt-4'></div>
                    {getPublicationDetail !== undefined && getPublicationDetail.publicationImage !== undefined && <div id='paidpublicationdiv'>
                        <form name="paymentGatewayrazorpay" onSubmit={handleSubmit(onSubmit)}>
                            <div className='col-12 mx-auto' >
                                <Card className="t-in-p">
                                    <div className='row mb-3'>
                                        <img className='mx-auto pay-pub-profile' src={getPublicationDetail.publicationImage.charAt(0) === '@' ? getPublicationDetail.publicationImage.substring(1) : getPublicationDetail.publicationImage} alt="author profile" />
                                    </div>
                                    <div className='text-center mb-2'>
                                        <span className='ff-lora fs-30 fw-bold'>{getPublicationDetail.publicationName}</span>
                                        <p className='fs-20 fw-mid'>{getPublicationDetail.about}</p>
                                        <p className='fs-32 fw-bold'>{getPublicationDetail.publicationPlan[0].planName}</p>
                                        <p className='fs-28 fw-bold mx-auto'>&#x20b9; {getPublicationDetail.publicationPrice}</p>
                                    </div>
                                    <p className='text-center fs-18 fw-normal'> {getPublicationDetail.publicationPlan[0].description} </p>
                                    <button type='submit' className='subscribe mx-auto px-4 d-flex' style={{ paddingTop: '0.4rem' }}>
                                        {submitLoader ? <CircularProgress style={{ width: '20px', height: '20px', color: '#fff' }} /> : <>Subscribe @ &#x20b9;{getPublicationDetail.publicationPrice} {getPublicationDetail.publicationPlan[0].planName}</>}
                                    </button>
                                </Card>
                            </div>
                        </form>
                    </div>}
                </>}
                {/* you may also like */}
                {getMorePubByInterest !== undefined && getMorePubByInterest.length > 0 && <div>
                    <div className='vertical-line my-4'></div>
                    <div className='row d-flex mb-3'>
                        <p className='mx-auto p-heading'>You may also like</p>
                    </div>
                    <Carousel responsive={responsive} >
                        {getMorePubByInterest.map((obj, index) => {
                            const imageUrl = obj.publicationImage.charAt(0) === '@' ? obj.publicationImage.substring(1) : obj.publicationImage
                            return (<Card className="mb-4 morePub-card" key={index}>
                                <CardMedia component="img" height="250" image={imageUrl} alt="publication profile" className='morePub-cardmedia' />
                                <div className='px-2 pb-2'>
                                    <p className='fs-24 fw-bold'>{obj.publicationName.slice(0, 15) + (obj.publicationName.length > 15 ? "..." : "")}</p>
                                    <p className='fs-14'> {obj.description.slice(0, 30) + (obj.description.length > 30 ? "..." : "")} </p>
                                    <p className='fs-14 fc-link' >View the Publication</p>
                                </div>
                            </Card>)
                        })}
                    </Carousel>
                </div>}
            </div>}
        </> : <div style={{ padding: '150px 0px', textAlign: 'center', marginTop: '4rem', marginBottom: '2rem' }}>
            <CircularProgress aria-label="Loading..." />
        </div>
        }
        <Footer />
    </>)
}

export default PublicationProfile

// pic penName from array and push in another array with and
// const user = [];
// for (let i = 0; i < response.publicationAuthor.length; i++) {
//     const element = response.publicationAuthor[i].authorPenName;
//     const name = element.charAt(0) === '@' ? element.substring(1) : element;
//     user.push(name)
// }
// var data = user.join(', ');
// var finalData = data.replace(/,([^,]*)$/, ' and ' + '$1');
// setauthorNameList(finalData) //store in state for author and co-author names


{/* testimonials */ }
{/* <div className='row'>
    <div className='col-12 text-center'>
        <p className='p-heading'>Testimonials</p>
    </div>
    <div className='col-6 mb-4'>
        <Card className="t-in-p">
            <CardContent>
                <img src={Quote} className='mb-3' alt='double quote' />
                <p style={{ fontSize: '20px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
            </CardContent>
            <CardHeader style={{ marginTop: '-30px' }}
                avatar={<Avatar style={{ width: '50px', height: '50px' }}> R </Avatar>}
                titleTypographyProps={{ variant: 'h6' }}
                title="User name"
                subheaderTypographyProps={{ variant: 'subtitle2' }}
                subheader="About the user"
            />
        </Card>
    </div>
    <div className='col-6'>
        <Card className="t-in-p">
            <CardContent>
                <img src={Quote} className='mb-3' alt='double quote' />
                <p style={{ fontSize: '20px' }}>Lorem ipsum dolor sit amet, consectetur Utaliquip ex ea commodo consequat. Duis aute irure dolor </p>
            </CardContent>
            <CardHeader style={{ marginTop: '-30px' }}
                avatar={<Avatar style={{ width: '50px', height: '50px' }}> R </Avatar>}
                titleTypographyProps={{ variant: 'h6' }}
                title="User name"
                subheaderTypographyProps={{ variant: 'subtitle2' }}
                subheader="About the user"
            />
        </Card>
    </div>
</div> */}
