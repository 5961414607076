import React, { useState, useEffect, useContext } from 'react'
import Axios from "axios";
import $ from 'jquery'
import { isMobile } from "react-device-detect"
import { useHistory } from "react-router-dom";
import { Avatar, Card } from '@material-ui/core'
import { CheckCircleOutline, AssignmentIndOutlined, ArrowDropDown, Menu, ShareRounded } from '@material-ui/icons'
import thinklyJsonData from '../../../configs/individualThinkly.json'
import { baseUrl, baseUrlThinkly } from '../../apiContent/api';
import NewThinkly from '../../thinklyLite/homePage/newThinkly'
import NewPublication from '../../thinklyLite/homePage/newPublication'
import SharePage from './sharePage'
import '../css/header.css'
import '../../common/font.css'
import Logo from '../images/thinkly-logo-.png'
import ADDTnP from '../../thinklyLite/Images/addThinklyPublicationIcon.svg'
import PlayStore from "../images/playStore.svg";
import AppStore from "../images/appstore.svg";

const Header = (props) => {
    const history = useHistory();
    const BASE_URL = useContext(baseUrl);
    const BASE_URL_THINKLY = useContext(baseUrlThinkly);
    const emailValidate = (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
    const [getEmail, setEmail] = useState(false)
    const [EmailInput, setEmailInput] = useState()
    const [ErrorMsg, setErrorMsg] = useState('')
    const [userID, setuserID] = useState(false)  //stored props data of user ID
    const [userProfileImage, setuserProfileImage] = useState()  //stored props data of user profile image
    const [userPenName, setuserPenName] = useState()  //stored props data of user penName
    const [user_status, setuser_status] = useState() //stored props data of user loggedIn status
    const [pub_count, setpub_count] = useState(0)  //stored props data of user publication count
    const [thinklyRemoteConfigData, setthinklyRemoteConfigData] = useState()  //stored props data of thinkly remote config json
    const [showThinkly, setshowThinkly] = useState(false)  //hide and show modal box of new thinkly
    const [showPublication, setshowPublication] = useState(false)  //hide and show modal box of new publication
    const [getPath, setPath] = useState('') //stored url fetch path
    const [showShareUrlPopup, setshowShareUrlPopup] = useState(false)  //hide and show modal popup of share url 
    const [showForUserProfile, setshowForUserProfile] = useState(false)
    const [isPartialUser, setisPartialUser] = useState(false)  //if user not registered in app then don't show create button
    const [IsEnabled, setIsEnabled] = useState(false)  //if isEnabled true then show new user profile detailed page else old one
    const [openInAppUrl, setopenInAppUrl] = useState(null)  //dynamic link for mob
    const [ShareUrl, setShareUrl] = useState()  //store profile url here
    const [showPubContent, setshowPubContent] = useState(false)

    useEffect(() => { //all props related data fetched and set in state here
        if (props.userStatus !== undefined && props.publicationCount !== undefined && props.authorID !== undefined) {
            setuserID(props.authorID)
            setuser_status(props.userStatus)
            setpub_count(props.publicationCount)
        }
        if (props.user_profile !== undefined && props.user_profile !== null) { //to get user pen name and profile image
            var data = props.user_profile.profileDetails
            var name = data.penName.charAt(0) === '@' ? data.penName.substring(1) : data.penName
            setuserPenName(name)
            setShareUrl(data.profileUrl)
            setuserProfileImage(data.profileImage);
            setisPartialUser(data.isPartialProfile)
            setIsEnabled(data.isSupportEnabled)
        }
        if (props.showContentForUserProfile !== undefined && props.userProfile !== undefined) {
            var data = props.userProfile.profileDetails
            var name = data.penName.charAt(0) === '@' ? data.penName.substring(1) : data.penName
            setuserPenName(name)
            setuser_status(props.loginStatus)
            setuserProfileImage(data.profileImage);
            setisPartialUser(data.isPartialProfile)
            setIsEnabled(data.isSupportEnabled)
            setshowForUserProfile(props.showContentForUserProfile)
        }

        // for publicaation detail page
        // const localUserID = window.sessionStorage.getItem('UserID')
        // const localUserStatus = window.sessionStorage.getItem('userStatus')
        // const pubHeader = window.sessionStorage.getItem('PubHeader')
        // console.log(localUserID, localUserStatus);
        // if (localUserID !== null && localUserStatus !== null && pubHeader !== null) {
        //     console.log("inside local condition");
        //     setshowPubContent(pubHeader)
        //     if (localUserStatus === 'S') {
        //         setuser_status('Success')
        //     }
        //     setuserID(localUserID)
        //     fetchUserProfileData(localUserID) //function call for user profile detail
        // }

        if (props.thinklyConfigJSON !== undefined && props.thinklyConfigJSON !== null) { //config data coming from firebase
            setthinklyRemoteConfigData(props.thinklyConfigJSON)
        } else if (thinklyJsonData !== undefined && thinklyJsonData !== null) {
            setthinklyRemoteConfigData(thinklyJsonData)
        }
    }, [])

    function fetchUserProfileData(authorID) {
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorID
            },
        };
        Axios.get(`${BASE_URL}User/GetUserProfileByID/${authorID}`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    const response = res.data.responseData.profileDetails
                    const userpenname = response.penName.charAt(0) === '@' ? response.penName.substring(1) : response.penName
                    setuserPenName(userpenname)
                    setuserProfileImage(response.profileImage)
                    const url = response.profileUrl.charAt(0) === '@' ? response.profileUrl.substring(1) : response.profileUrl
                    setShareUrl(url)
                }
            })
            .catch((err) => {
                console.log("GetUserProfileByID error in catch", err);
            });
    }

    useEffect(() => {  //set path value on base of url handled here
        if (userPenName !== undefined) {
            const link = "https://app.thinkly.me/?&apn=com.me.digicita.thinkly.dev&ibi=com.Thinkly.Thinkly&imv=10.0&isi=1329943323&link=https://test.thinkly.me/thinkly/@" + userPenName
            setopenInAppUrl(link)  //for mobile view open in app dynamic link
        }
        var thePath = window.location.href;
        var path = thePath.substring(thePath.lastIndexOf('/') + 1)
        if (path === 'signup') {
            setPath('signup')
        } else if (path === 'signIn') {
            setPath('signIn1') // signIn (not in use cause creator only allow signin)
        } else if (path === 'complete-your-profile') {
            setPath('complete-your-profile')
        } else if (path === 'checkUser') {
            setPath('checkUser')
        } else if (user_status === 'Success' || user_status === 'S') {
            setPath('LoggedIn')
            // } else if (thePath.includes('publication')) {
            //     setshowPubContent(true)
        } else {
            setPath('')
        }
    }, [user_status, userID, pub_count, thinklyRemoteConfigData, userProfileImage, userPenName])

    const handleCreate = () => {  //onClick of create button dropdown will hide and show
        if ($('.dropdown-content').css('display') == 'none') {
            $('.dropdown-content').css('display', 'block');
        } else {
            $('.dropdown-content').css('display', 'none');
        }
    }

    const handleThinklyClick = () => {  //onClick of new thinkly dropdown menu option modal will popup and dropdown will hide
        setshowThinkly(true)
        $('.dropdown-content').css('display', 'none');
    }

    const handlePublicationClick = () => {  //onClick of new publication dropdown menu option modal will popup and dropdown will hide
        setshowPublication(true)
        $('.dropdown-content').css('display', 'none');
    }

    const handleSignUpClick = () => {  //to switch path url and page UI base of either login or signup
        history.push('/signup')
        setPath('signup')
    }

    const handleLoginClick = () => {  //to switch path url and page UI base of either login or signup
        history.push('/login')
        setPath('signIn')
    }

    const sendSignUpEmail = () => {  //send email to user for app link
        var config = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            data: {
                EventType: "SignUp",
                NotificationType: "Email",
                ReceiverHandle: EmailInput
            }
        }
        if (EmailInput.match(emailValidate)) {
            setErrorMsg()
            Axios(`${BASE_URL_THINKLY}Notification/SendEmailNotification`, config)
                .then((res) => {
                    if (res.data.responseCode === '00') {
                        setEmail(true)
                    } 
                })
                .catch((err) => {
                    console.log("inside catch", err);
                });
        } else {
            setErrorMsg("Please provide a valid email ID")
        }
    }

    const handleUserProfle = () => {  //onClick of user profile button dropdown will hide and show
        if ($('.dropdown-user').css('display') === 'none') {
            $('.dropdown-user').css('display', 'block');
        } else {
            $('.dropdown-user').css('display', 'none');
        }
    }

    const handleLogout = () => {  //onClick of logout it will clear all history and local&Session storage 
        window.localStorage.clear();
        window.sessionStorage.clear();
        history.push('/signIn')
        // setshowPubContent(false)
        setshowForUserProfile(false)
    }

    const handleViewProfile = () => {  //onClick of view profile take to new tab for profile detail page
        var newWindow = ""
        if (IsEnabled) {
            newWindow = window.open(`${userPenName}`, '_blank')
        } else {
            newWindow = window.open(`${ShareUrl}`, '_blank')
        }
        newWindow.penName = userPenName
        newWindow.userStauts = user_status
    }

    function createButton(pCount) {  //create button UI code for new thinkly and publication ---> on purpose of code reusability
        return (<div className='col-4 dropdown' style={{ marginTop: '7px' }}>
            <button className='fw-mid-bold border-radius-4 fc-white border-none primary-bg-color height-button fs-18 px-3 text-center' style={{ outlineWidth: '0' }} onClick={() => handleCreate()} >
                <img src={ADDTnP} /> Create <ArrowDropDown />
            </button>
            <div class="dropdown-content" >
                {pCount !== undefined && pCount === 0 ? '' : <a href="#createNewThinkly" data-toggle="modal" data-target="#createThinkly" onClick={() => handleThinklyClick()}>New Post</a>}
                <a href="#createNewPublication" data-toggle="modal" data-target="#newPublication" onClick={() => handlePublicationClick()}>New Publication</a>
            </div>
        </div>)
    }

    return (<>
        <section className="topsection fixed-top">
            <nav className="navbrpadding">
                <div className="container">
                    <div className="row d-flex">
                        <div className="col-md-6">
                            <img className="header-logo" src={Logo} alt="Header Logo" />
                            {/* {!isMobile ? <h6 className="mobheader" style={{ marginTop: '-44px', marginLeft: '168px', marginRight: '-110px' }}>
                                <span className="sideline" >Where the world comes to think</span>
                            </h6> : <> */}
                            {isMobile && <>
                                {(process.env.REACT_APP_ALLOW_SIGNUP === "YES" && getPath === 'signup') ? <span className='float-right mt-1'>Existing User? <text className='fc-link' onClick={() => handleLoginClick()}> Sign In </text> </span>
                                    : getPath === 'signIn' ? <span className='float-right mt-1'>New User? <text className='fc-link' onClick={() => handleSignUpClick()}> Sign Up </text> </span>
                                        : getPath === 'LoggedIn' && !showForUserProfile && !isPartialUser && <div className='float-right' style={{ marginTop: '0px' }}>
                                            <Card className='float-right p-1' style={{ borderRadius: '50%', marginLeft: '-40px', position: 'absolute', zIndex: '9' }} data-toggle="modal" data-target="#ShareProfile" onClick={() => setshowShareUrlPopup(true)}> <ShareRounded /> </Card>
                                            <Card style={{ borderRadius: '40px', paddingTop: '4px', paddingBottom: '4px', paddingLeft: '10px', paddingRight: '4px' }}>
                                                {(userProfileImage !== undefined && userProfileImage !== null && userProfileImage !== '') ?
                                                    <img src={userProfileImage.charAt(0) === '@' ? userProfileImage.substring(1) : userProfileImage} alt="" style={{ width: '22px', height: '22px', borderRadius: '50%' }} />
                                                    : <Avatar style={{ width: '22px', height: '22px' }} src={<AssignmentIndOutlined />} />}
                                                <ArrowDropDown onClick={() => handleUserProfle()} />
                                                <div class="dropdown-user" >
                                                    <a onClick={() => handleViewProfile()}>View My Page</a>
                                                    <a onClick={() => handleLogout()}>Sign Out</a>
                                                </div>
                                            </Card>
                                        </div>}
                                {showForUserProfile && <div className='float-right' style={{ marginTop: '0px' }}>
                                    <Card className='float-right p-1' style={{ borderRadius: '50%', marginLeft: '-40px', position: 'absolute', zIndex: '9' }} data-toggle="modal" data-target="#ShareProfile" onClick={() => setshowShareUrlPopup(true)}>
                                        <ShareRounded />
                                    </Card>
                                    <Card style={{ borderRadius: '40px', paddingTop: '4px', paddingBottom: '4px', paddingLeft: '10px', paddingRight: '4px', height: '34px' }}>
                                        {(user_status === 'Success' && userProfileImage !== undefined && userProfileImage !== null && userProfileImage !== '') ?
                                            <img src={userProfileImage.charAt(0) === '@' ? userProfileImage.substring(1) : userProfileImage} alt="" style={{ width: '22px', height: '22px', borderRadius: '50%' }} />
                                            : <Avatar style={{ width: '22px', height: '22px' }} src={<AssignmentIndOutlined />} />}
                                        {/* <Avatar style={{ width: '22px', height: '22px', marginTop: '3px' }} src={<AssignmentIndOutlined />} /> */}
                                        <ArrowDropDown onClick={() => handleUserProfle()} style={{ marginTop: '-48px', marginLeft: '20px' }} />
                                        <div class="dropdown-user" >
                                            {user_status === 'Success' ? <a onClick={() => handleLogout()}>Sign out </a> : <a onClick={() => handleLogout()}>Sign In</a>}
                                        </div>
                                    </Card>
                                </div>}
                            </>}
                        </div>
                        {/* signIn and signup href for desktop */}
                        {!isMobile && <>
                            {(process.env.REACT_APP_ALLOW_SIGNUP === "YES" && getPath === 'signup') ? <div className="col-md-6 py-1" style={{ marginLeft: '-10px' }}>
                                <span className='float-right'>Existing User?
                                    <text className='fc-link' onClick={() => handleLoginClick()}> Sign In</text>
                                </span>
                            </div> : (getPath === 'signIn') ? <div className="col-md-6 py-1" style={{ marginLeft: '-10px' }}>
                                <span className='float-right'>New User?
                                    <text className='fc-link' onClick={() => handleSignUpClick()}> Sign Up </text>
                                </span>
                            </div> : ''}
                        </>}
                        {/* header logo content end && loggedIn user condition starts*/}
                        {!isMobile && getPath === 'LoggedIn' && !showForUserProfile && !isPartialUser && <>
                            {thinklyRemoteConfigData !== undefined && thinklyRemoteConfigData.allowIndividualPost === 'Y' ? <>
                                {createButton()}
                            </> : thinklyRemoteConfigData !== undefined && thinklyRemoteConfigData.allowIndividualPost === 'N' ? <>
                                {pub_count > 0 ? <> {createButton()} </> : <> {createButton(pub_count)} </>}
                            </> : ''}
                            {/* profile and rest of icons */}
                            <div className='col-1' style={{ marginTop: '12px' }}>
                                <Card className='float-right p-1' style={{ borderRadius: '50%' }} data-toggle="modal" data-target="#ShareProfile" onClick={() => setshowShareUrlPopup(true)}>
                                    <ShareRounded />
                                </Card>
                            </div>
                            <div className='col-1' style={{ marginTop: '12px' }}>
                                <Card onClick={() => handleUserProfle()} style={{ borderRadius: '40px', paddingTop: '4px', paddingBottom: '4px', paddingLeft: '10px' }}>
                                    <Menu />
                                    {(userProfileImage !== undefined && userProfileImage !== null && userProfileImage !== '') ?
                                        <img src={userProfileImage.charAt(0) === '@' ? userProfileImage.substring(1) : userProfileImage} alt="" style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                                        : <Avatar style={{ width: '25px', height: '25px', marginTop: "-24px", marginLeft: '25px' }} src={<AssignmentIndOutlined />} />}
                                    <div class="dropdown-user">
                                        <a onClick={() => handleViewProfile()}>View My Page</a>
                                        <a onClick={() => handleLogout()}>Sign out</a>
                                    </div>
                                </Card>
                            </div>
                        </>}
                        {/* for user profile only */}
                        {!isMobile && showForUserProfile && <>
                            <div className='col-4'></div>
                            <div className='col-1' style={{ marginTop: '12px' }}>
                                <Card className='float-right p-1' style={{ borderRadius: '50%' }} data-toggle="modal" data-target="#ShareProfile" onClick={() => setshowShareUrlPopup(true)}>
                                    <ShareRounded />
                                </Card>
                            </div>
                            <div className='col-1' style={{ marginTop: '12px' }}>
                                <Card onClick={() => handleUserProfle()} style={{ borderRadius: '40px', paddingTop: '4px', paddingBottom: '4px', paddingLeft: '10px' }}>
                                    <Menu />
                                    {user_status === 'Success' ? <>
                                        {(userProfileImage !== undefined && userProfileImage !== null && userProfileImage !== '') ?
                                            <img src={userProfileImage.charAt(0) === '@' ? userProfileImage.substring(1) : userProfileImage} alt="" style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
                                            : <Avatar style={{ width: '25px', height: '25px', marginTop: "-24px", marginLeft: '25px' }} src={<AssignmentIndOutlined />} />}
                                    </> : <Avatar style={{ width: '25px', height: '25px', marginTop: "-24px", marginLeft: '25px' }} src={<AssignmentIndOutlined />} />}
                                    <div class="dropdown-user">
                                        {user_status === 'Success' ? <a onClick={() => handleLogout()}>Sign out</a> : <a onClick={() => handleLogout()}>Sign in</a>}
                                    </div>
                                </Card>
                            </div>
                        </>}
                    </div>
                </div>
            </nav>
        </section>

        {showThinkly && <NewThinkly authorID={userID} thinklyRemoteConfigData={thinklyRemoteConfigData} />}
        {showPublication && <NewPublication authorID={userID} />}
        {showShareUrlPopup && <SharePage profile={userProfileImage} penName={userPenName} shareUrl={ShareUrl} />}

        {/* modal popup for email trigger */}
        <div id="myModal" class="modal fade in" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content modal-background">
                    <button type="button" class="close text-right pr-2" data-dismiss="modal" onClick={() => setEmail(false)}>&times;</button>
                    <div class="modal-body px-4 py-2">
                        <div className="row">
                            {!getEmail ? <div className='col-12 text-center'>
                                <p className="font-weight-bold fs-20">Download the App for a richer experience! </p>
                                <p className='fs-15'>Follow your favourite publications, engage in meaningful conversations, earn & redeem Thinkly Stars for exciting rewards and many more features.</p>
                                {isMobile ? <>
                                    <div className="row text-center mb-3">
                                        <div className="col-6 text-right">
                                            <a href={openInAppUrl}>
                                                <img src={PlayStore} style={{ width: '9rem', borderRadius: '10px' }} alt="Download button for Play store" />
                                            </a>
                                        </div>
                                        <div className="col-6 text-left">
                                            <a href={openInAppUrl}>
                                                <img src={AppStore} style={{ width: '8rem' }} alt="Download button for App store" />
                                            </a>
                                        </div>
                                    </div>
                                </> : <>
                                    <input id='email' className='email-link mt-3 fs-18 text-center ff-roboto' placeholder='Enter your Email ID' onChange={(e) => setEmailInput(e.target.value)} required /> <br />
                                    <label style={{ fontSize: '12px', color: 'red' }}>{ErrorMsg !== undefined && ErrorMsg !== null && ErrorMsg}</label> <br />
                                    <button type='button' className='fw-mid border-radius-4 fc-white border-none primary-bg-color height-button fs-18 ff-roboto' style={{ width: '60%' }} onClick={() => sendSignUpEmail()}>Send Download Link</button> <br />
                                    <p className='fs-15 mt-1' style={{ color: 'gray' }}>You will receive an email to download the app</p>
                                </>}
                            </div> : <div className='col-12 text-center'>
                                <CheckCircleOutline style={{ color: 'green', width: '80px', height: '80px' }} /> <br />
                                <h3>Download link sent</h3> <br />
                                <p style={{ marginTop: '-24px' }}>If you didn't get the email, check your spam folder</p>
                                <button type="button" class="button1 mt-3 mb-4" data-dismiss="modal" onClick={() => setEmail(false)}>OK</button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}

export default Header;

//const downloadApp = () => {   //app store link for andriod and IOS
//     var userAgent = navigator.userAgent || navigator.vendor;
//     if (/android/i.test(userAgent)) {
//         return (
//             window.location = "https://play.google.com/store/apps/details?id=com.me.digicita.thinkly"
//         )
//     }
//     // /iPad|iPhone|iPod/.test(userAgent)
//     if (/iPhone/i.test(userAgent)) {
//         return (
//             window.location = "https://apps.apple.com/in/app/thinkly/id1329943323"
//         )
//     }
// }
