import React, { useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import Axios from "axios";
import { ListItemText, CircularProgress, Avatar } from "@material-ui/core";
import AssignmentIndOutlinedIcon from "@material-ui/icons/AssignmentIndOutlined";
import { baseUrl } from "../../apiContent/api.jsx";
import DetailedPart from "./detailedPart";
import "../css/detailedPage.css"
import PaidLogo from "../images/paidthinkly.png";

const Index = () => {
    const BASE_URL = useContext(baseUrl);
    const [GetSummaryDetails, setSummaryDetails] = useState();
    const [downloadPannel, setdownloadPannel] = useState(false);

    useEffect(() => {
        var summaryID = window.location.href
        var summary_ID = summaryID.substring(summaryID.lastIndexOf("/") + 1, summaryID.length);
        GetSummaryDetailsByID2(summary_ID);
    }, [])

    function GetSummaryDetailsByID2(thinklyID) {
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": "21723"
            },
        };
        Axios.get(`${BASE_URL}Thinkly/v2/GetThinklyDetailsByID/${thinklyID}`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    setSummaryDetails(res.data.responseData.ThinklyDetails);
                }
            })
            .catch((err) => {
                console.log("GetSummaryDetailsByID error in catch", err);
            });
    }

    useEffect(() => {
        if (GetSummaryDetails !== undefined && GetSummaryDetails !== null && GetSummaryDetails.length > 0) {
            if (GetSummaryDetails.publicationData !== null && GetSummaryDetails.publicationData.publicationPayType === "Paid") {
                disable();
            }
        }
    }, [GetSummaryDetails])

    const activeDownloadPannel = () => {  //for bottom static pannel(download link) on call to action
        setdownloadPannel(true);
    }

    function disable() {  //for disable scroll once background blurred
        document.getElementById('fadeIT').classList.add("blur-body");
        document.body.classList.add("stop-scrolling");
    }

    const getEmailIDforLink = () => {
        setdownloadPannel(false);
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent)) {
            return (
                window.location = "https://play.google.com/store/apps/details?id=com.me.digicita.thinkly"
            )
        }
        if (/iPad|iPhone|iPod/.test(userAgent)) {
            return (
                window.location = "https://apps.apple.com/in/app/thinkly/id1329943323"
            )
        }
    }

    return (<>
        {GetSummaryDetails !== undefined && GetSummaryDetails !== null ? <>
            <div id="fadeIT" className="container">
                <div className="row d-flex">
                    <div className="col-md-7 mx-auto">
                        <DetailedPart SummaryDetails={GetSummaryDetails} activeDownloadPannel={() => activeDownloadPannel()} />
                    </div>
                </div>

                {/* call to action bottom pannel for mobile view, using modal popup for desktop view(header) */}
                {isMobile && <div className="row">
                    {downloadPannel && <div>
                        <section className="bottom-section-mob">
                            <div className="top-hr-colored"></div>
                            <div className="col-12 py-2 mt-2">
                                {GetSummaryDetails.publicationData !== null ? <>
                                    {GetSummaryDetails.publicationData.publicationImage !== undefined ?
                                        <img src={GetSummaryDetails.publicationData.publicationImage.charAt(0) === '@' ? GetSummaryDetails.publicationData.publicationImage.substring(1) : GetSummaryDetails.publicationData.publicationImage} alt="" style={{ width: '32px', height: '32px', borderRadius: '50%' }} />
                                        : <Avatar src={<AssignmentIndOutlinedIcon />} />
                                    }
                                    <span className="ml-2">See All Thinklies From <b>
                                        {GetSummaryDetails.publicationData.penName.charAt(0) === '@' ? GetSummaryDetails.publicationData.penName.substring(1) : GetSummaryDetails.publicationData.penName}
                                    </b></span>
                                </> : <>
                                    {GetSummaryDetails.authorData.authorProfileImage !== undefined ?
                                        <img src={GetSummaryDetails.authorData.authorProfileImage.charAt(0) === '@' ? GetSummaryDetails.authorData.authorProfileImage.substring(1) : GetSummaryDetails.authorData.authorProfileImage} alt="" style={{ width: '32px', height: '32px', borderRadius: '50%' }} />
                                        : <Avatar src={<AssignmentIndOutlinedIcon />} />
                                    }
                                    <span className="ml-2">See All Thinklies From <b>
                                        {GetSummaryDetails.authorData.authorPenName.charAt(0) === '@' ? GetSummaryDetails.authorData.authorPenName.substring(1) : GetSummaryDetails.authorData.authorPenName}
                                    </b></span>
                                </>}
                            </div>
                            <div className="col-12">
                                <input className="float-right downloadLink-button" style={{ marginTop: '-42px', marginRight: '-10px' }} type="button" value="Open App" onClick={() => getEmailIDforLink()} />
                                <span className="float-right font-weight-bold" style={{ fontSize: '28px', marginTop: '-90px', marginRight: '-9px' }} onClick={() => setdownloadPannel(false)}>&times;</span>
                            </div>
                        </section>
                    </div>}
                </div>}

                {/* paid thinkly bottomsheet */}
                {/* {GetSummaryDetails.publicationData.publicationPayType === "Paid" && <div className="shell-container show" style={isMobile ? { padding: '40px 20px' } : { padding: '40px' }}>
                    {isMobile ? <div className="row">
                        <div className="col-12" style={{ textAlign: 'center' }}>
                            <img src={PaidLogo} alt="paid thinkly" style={{ width: '60px', height: '60px' }} />
                            <ListItemText
                                primary={<p className="header-font" style={{ fontSize: '20px', fontWeight: 'bold', color: '#fff' }}> This is a Premium Thinkly </p>}
                                secondary={<p style={{ fontSize: '15px', color: '#fff', paddingRight: '10px', marginTop: '-18px' }}>
                                    This Thinkly is a part of paid Publication. Pay on the app to get unlimited access to this publication.
                                </p>}
                            />
                            <input className="subscribe-button" type="button" value="Subscribe on the App" />
                        </div>
                    </div> : <div className="row">
                        <div className="col-1 ml-5">
                            <img src={PaidLogo} alt="paid thinkly" style={{ width: '80px', height: '80px' }} />
                        </div>
                        <div className="col-8 mt-3">
                            <ListItemText style={{ marginTop: '6px', marginLeft: '-10px' }}
                                primary={<p className="header-font" style={{ fontSize: '28px', fontWeight: 'bold', color: '#fff', lineHeight: '8px' }}> This is a Premium Thinkly </p>}
                                secondary={<p style={{ fontSize: '20px', color: '#fff' }}>
                                    This Thinkly is a part of paid Publication. Pay to get access to all thinklies in this publication.
                                </p>}
                            />
                            <input className="subscribe-button" style={{ marginTop: '6px', marginLeft: '-10px' }} type="button" value="Subscribe on the App" />
                        </div>
                    </div>}
                </div>} */}
            </div>
        </> : <div style={isMobile ? { padding: '250px 0px', textAlign: 'center' } : { padding: '100px 0px', textAlign: 'center' }}>
            <CircularProgress aria-label="Loading..." />
        </div>}
    </>)
}

export default Index;


// return (<>
//     {GetSummaryDetails !== undefined && GetSummaryDetails !== null ? <>
//         <div id="fadeIT" className="container-fluid body-align">
//             <div className="row">
//                 {!isMobile && <div className={GetSummaryDetails.postData.postImages.length === 0 ? "col-md-3 mt-3" : "col-md-3 sticky-left-section"} >
//                     <Left SummaryDetails={GetSummaryDetails} activeDownloadPannel={() => activeDownloadPannel()} imageCount={GetSummaryDetails.postData.postImages.length} />
//                 </div>}
//                 <div className="col-md-6">
//                     <DetailedPart SummaryDetails={GetSummaryDetails} activeDownloadPannel={() => activeDownloadPannel()} />
//                 </div>
//                 <div className="col-md-3 comment-top-align">
//                     {/* <Right activeDownloadPannel={() => activeDownloadPannel()} /> */}
//                 </div>
//                 {isMobile && <div className="col-md-3 mt-5">
//                     <Left SummaryDetails={GetSummaryDetails} activeDownloadPannel={() => activeDownloadPannel()} />
//                 </div>}
//             </div>

//             {/* call to action bottom pannel for mobile view, using modal popup for desktop view(header) */}
//             {isMobile && <div className="row">
//                 {downloadPannel && <div className="row" style={{ backgroundColor: "#fff", padding: '10px 36px 10px 56px', position: 'fixed', zIndex: '9', bottom: '0px' }}>
//                     <div className="row" style={{ textAlign: 'left' }}>
//                         <span className="font-weight-bold" style={{ fontSize: '16px' }}>Download the Thinkly App for richer experience!</span>
//                         <p style={{ fontSize: '14px' }}>Follow your favourite publication, engage in meaningful conversations, earn & redeem Thinkly s for exciting rewards and many more features</p>
//                     </div>
//                     <div className="col-12" style={{ textAlign: 'left' }}>
//                         <input className="downloadLink-button" type="button" value="Get the App" onClick={() => getEmailIDforLink()} style={{ marginTop: '5px', marginLeft: '-25px' }} />
//                     </div>
//                 </div>}
//             </div>}

//             {/* paid thinkly bottomsheet */}
//             {GetSummaryDetails.postData.postPayType !== 'Free' && <div className="shell-container show" style={isMobile ? { padding: '40px 20px' } : { padding: '40px' }}>
//                 {isMobile ? <div className="row">
//                     <div className="col-12" style={{ textAlign: 'center' }}>
//                         <img src={PaidLogo} alt="paid thinkly" style={{ width: '60px', height: '60px' }} />
//                         {/* <div className="col-" style={{ marginLeft: '5rem' }} > style={{ marginTop: '-48px' }} */}
//                         <ListItemText
//                             primary={<p className="header-font" style={{ fontSize: '20px', fontWeight: 'bold', color: '#fff' }}> This is a Premium Thinkly </p>}
//                             secondary={<p style={{ fontSize: '15px', color: '#fff', paddingRight: '10px', marginTop: '-18px' }}>
//                                 This Thinkly is a part of paid Publication. Pay on the app to get unlimited access to this publication.
//                             </p>}
//                         />
//                         <input className="subscribe-button" type="button" value="Subscribe on the App" />
//                     </div>
//                 </div> : <div className="row">
//                     <div className="col-1 ml-5">
//                         <img src={PaidLogo} alt="paid thinkly" style={{ width: '80px', height: '80px' }} />
//                     </div>
//                     <div className="col-8 mt-3">
//                         <ListItemText style={{ marginTop: '6px', marginLeft: '-10px' }}
//                             primary={<p className="header-font" style={{ fontSize: '28px', fontWeight: 'bold', color: '#fff', lineHeight: '8px' }}> This is a Premium Thinkly </p>}
//                             secondary={<p style={{ fontSize: '20px', color: '#fff' }}>
//                                 This Thinkly is a part of paid Publication. Pay to get access to all thinklies in this publication.
//                             </p>}
//                         />
//                         <input className="subscribe-button" style={{ marginTop: '6px', marginLeft: '-10px' }} type="button" value="Subscribe on the App" />
//                     </div>
//                 </div>}
//             </div>}
//         </div>
//     </> : <div style={{ padding: '100px', textAlign: 'center' }}>
//         <CircularProgress aria-label="Loading..." />
//     </div>}
// </>)