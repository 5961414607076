import React, { useEffect, useState } from 'react'
import { Avatar, ListItemText, Card, CircularProgress } from "@material-ui/core"
import { AssignmentIndOutlined } from "@material-ui/icons"
import { useHistory } from "react-router-dom"
import BioLink from '../Images/bio-link.svg'
import '../css/homePage.css';

const ProfileDetail = (props) => {
    const history = useHistory();
    const [profileData, setProfileData] = useState();
    const [loginStatus, setloginStatus] = useState()
    const [aboutMe, setaboutMe] = useState();
    const [selectedIndex, setSelectedIndex] = useState(0) //set index of thinkly type
    const [CopyUrl, setCopyUrl] = useState(false)
    const [isPartialUser, setisPartialUser] = useState(false)
    const [IsEnabled, setIsEnabled] = useState(false)
    const [UserUrl, setUserUrl] = useState()
    const [supporterData, setsupporterData] = useState()
    const [UserBalance, setUserBalance] = useState()

    useEffect(() => {
        if (props.profileJson !== undefined && props.userStatus !== undefined && props.supporterData !== undefined && props.UserBalance !== undefined) {
            setProfileData(props.profileJson)
            setloginStatus(props.userStatus)
            setsupporterData(props.supporterData) //userBalance and supporterCount
            setUserBalance(props.UserBalance) // my stars balance
        }
    }, []);

    useEffect(() => {
        if (profileData !== undefined && profileData !== null) {
            const t_name = profileData.profileDetails.aboutMe;
            const count = 20;
            const thinkly_name = t_name.slice(0, count) + (t_name.length > count ? "..." : "");
            setaboutMe(thinkly_name)
            if (!profileData.profileDetails.isSupportEnabled) {
                setUserUrl(profileData.profileDetails.profileUrl)
            } else {
                var data = profileData.profileDetails.penName
                var data1 = data.charAt(0) === '@' ? data.substring(1) : data
                var url = process.env.REACT_APP_BASE_URL + data1
                setUserUrl(url)
            }
            setisPartialUser(profileData.profileDetails.isPartialProfile)
            setIsEnabled(profileData.profileDetails.isSupportEnabled)
        }
    }, [profileData]);

    const handleListItemClick = (event, index, page) => {
        setSelectedIndex(index);
        props.profileDetail(page)
        if (index === 0) { 
            document.getElementById('publication').style.background = '#fff'
            document.getElementById('thinkly').style.background = '#fff'
            document.getElementById('dashboard').style.background = '#ffe7cc'
            document.getElementById('stars').style.background = '#fff'
            document.getElementById('drafts').style.background = '#fff'
            document.getElementById('scheduled').style.background = '#fff'
        } else if (index === 1) { 
            document.getElementById('dashboard').style.background = '#fff'
            document.getElementById('thinkly').style.background = '#fff'
            document.getElementById('publication').style.background = '#fff'
            document.getElementById('stars').style.background = '#ffe7cc'
            document.getElementById('drafts').style.background = '#fff'
            document.getElementById('scheduled').style.background = '#fff'
            document.getElementById('drafts').style.background = '#fff'
            document.getElementById('scheduled').style.background = '#fff'
        } else if (index === 2) { 
            document.getElementById('dashboard').style.background = '#fff'
            document.getElementById('publication').style.background = '#ffe7cc'
            document.getElementById('thinkly').style.background = '#fff'
            document.getElementById('stars').style.background = '#fff'
            document.getElementById('drafts').style.background = '#fff'
            document.getElementById('scheduled').style.background = '#fff'
        } else if (index === 3) { 
            document.getElementById('stars').style.background = '#fff'
            document.getElementById('dashboard').style.background = '#fff'
            document.getElementById('publication').style.background = '#fff'
            document.getElementById('thinkly').style.background = '#ffe7cc'
            document.getElementById('drafts').style.background = '#fff'
            document.getElementById('scheduled').style.background = '#fff'
        }else if (index === 4) { 
            document.getElementById('stars').style.background = '#fff'
            document.getElementById('dashboard').style.background = '#fff'
            document.getElementById('publication').style.background = '#fff'
            document.getElementById('thinkly').style.background = '#fff'
            document.getElementById('drafts').style.background = '#ffe7cc'
            document.getElementById('scheduled').style.background = '#fff'
        }else if (index === 5) { 
            document.getElementById('stars').style.background = '#fff'
            document.getElementById('dashboard').style.background = '#fff'
            document.getElementById('publication').style.background = '#fff'
            document.getElementById('thinkly').style.background = '#fff'
            document.getElementById('drafts').style.background = '#fff'
            document.getElementById('scheduled').style.background = '#ffe7cc'
        }
    }

    const copyLink = () => {
        if (!IsEnabled) {
            var url = document.getElementById("userShareUrl").innerHTML;
            navigator.clipboard.writeText(url).then(function () {
                setCopyUrl(true)
                setTimeout(() => {
                    setCopyUrl(false)
                }, 2000);
            }, function () {
                console.log('Copy error@@@')
            });
        } else {
            navigator.clipboard.writeText(UserUrl).then(function () {
                setCopyUrl(true)
                setTimeout(() => {
                    setCopyUrl(false)
                }, 2000);
            }, function () {
                console.log('Copy error')
            });
        }
    }

    const handleUserProfile = () => {
        if (!IsEnabled) {
            window.open(`${UserUrl}`, '_blank') //for old UI
        } else {
            var data = profileData.profileDetails.penName
            var data1 = data.charAt(0) === '@' ? data.substring(1) : data
            var newWindow = window.open(`/${data1}`, '_blank')
            newWindow.penName = data1  //this line is to pass penName to userProfile page
            newWindow.userStauts = loginStatus
        }
    }

    return (<>
        {profileData !== undefined && profileData !== null ? <div style={{ marginTop: '6rem' }}>
            <div className='row' onClick={() => handleUserProfile()} style={{ cursor: 'pointer' }}>
                {profileData.profileDetails.profileImage !== undefined && profileData.profileDetails.profileImage !== null ?
                    <img src={profileData.profileDetails.profileImage.charAt(0) === '@' ? profileData.profileDetails.profileImage.substring(1) : profileData.profileDetails.profileImage} alt="" style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                    : <Avatar style={{ width: '50px', height: '50px' }} src={<AssignmentIndOutlined />} />
                }
                <ListItemText style={{ marginTop: '7px', marginLeft: '15px' }} //onClick={() => props.profileDetail('ProfileDetail')} 
                    primary={<div style={{ lineHeight: '1', fontSize: '18px' }}>
                        <span className="header-font"> <b>{profileData.profileDetails.firstName + " " + profileData.profileDetails.lastName}</b> </span> </div>}
                    secondary={<span style={{ fontSize: '14px' }}>{aboutMe}</span>}
                />
                {/* <text className='float-right' style={{ fontSize: '12px', fontWeight: 'bold', color: '#ea7f00', marginTop: '7px' }}>EDIT</text> */}
            </div>
            <div className='row mt-4'>
                <div className='col-1 p-0'>
                    <img src={BioLink} alt='Bio_link' />
                </div>
                <div className='col-11' style={{ lineHeight: '0.9' }}>
                    <a style={{ fontSize: '12px', color: '#3c7493', overflowWrap: 'break-word' }} id="userShareUrl">{UserUrl}</a> <br />
                    <a style={{ fontSize: '12px', color: '#e98c37', fontWeight: '500', cursor: 'pointer' }} onClick={() => copyLink()}>{CopyUrl ? 'Copied' : 'Copy Link'}</a>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12' style={{ width: 'auto', paddingLeft: '0px', paddingRight: '0px' }}>
                    {!isPartialUser && <>
                        <Card className="p-2 mt-2" style={profileData.profileDetails.isSupportEnabled === false ? { display: 'none' } : { fontSize: '16px', fontWeight: '500', cursor: 'pointer', background: '#ffe7cc' }} id="dashboard" selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 0, 'Dashboard')}>
                            <text>My True-fans</text>
                            <text className='float-right'>{supporterData !== undefined && supporterData.TotalSupporters}</text>
                        </Card>
                        <Card className="p-2 mt-2" style={{ fontSize: '16px', fontWeight: '500', cursor: 'pointer' }} id="stars" selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 1, 'Stars')}>
                            <text>My Stars</text>
                            <text className='float-right'>{UserBalance !== undefined && UserBalance}</text>
                        </Card>
                        <Card className="p-2 mt-2" style={{ fontSize: '16px', fontWeight: '500', cursor: 'pointer' }} id="publication" selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 2, 'Publication')}>
                            <text>My Publications</text>
                            <text className='float-right'>{profileData.otherDetails.totalPublicationsCount}</text>
                        </Card>                      
                        <Card className="p-2 mt-2" style={{ fontSize: '16px', fontWeight: '500', cursor: 'pointer' }} id="thinkly" selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 3, 'Thinkly')}>
                            <text>My Posts</text>
                            <text className='float-right'>{profileData.otherDetails.totalThinkliesCount}</text>
                        </Card>                      
                        <Card className="p-2 mt-2" style={{ fontSize: '16px', fontWeight: '500', cursor: 'pointer' }} id="drafts" selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 4, 'Drafts')}>
                            <text>My Drafts</text>
                            <text className='float-right'></text>
                        </Card>
                        <Card className="p-2 mt-2" style={{ fontSize: '16px', fontWeight: '500', cursor: 'pointer' }} id="scheduled" selected={selectedIndex === 0} onClick={(event) => handleListItemClick(event, 5, 'Scheduled')}>
                            <text>My Scheduled Posts</text>
                            <text className='float-right'></text>
                        </Card>
                    </>}
                </div>
            </div>
        </div> : <CircularProgress aria-label="Loading..." />}
    </>)
}

export default ProfileDetail;

{/* <Card className="p-2 mt-4" style={{ fontWeight: '500' }} >
        <text>Announcements</text>
        <ul>
            <li> <ListItemText
                primary={<text style={{ fontSize: '14px' }}>54 new reads on your publised post in Cosmos</text>}
                secondary={<text style={{ fontSize: '12px' }}>Click here for more details.</text>}
            /> </li>
            <li> <ListItemText
                primary={<text style={{ fontSize: '14px' }}>Thinklyintroduces a new  type of content. Coming Soon!</text>}
                secondary={<text style={{ fontSize: '12px' }}>Click here for more details.</text>}
            /> </li>
            <li> <ListItemText
                primary={<text style={{ fontSize: '14px' }}>Ask other authors to co-author on your publication with one click</text>}
                secondary={<text style={{ fontSize: '12px' }}>Click here for more details.</text>}
            /> </li>
        </ul>
    </Card> */}

{/* <div className='row mt-4'>
        {profileData.otherDetails.commonInterests !== undefined && profileData.otherDetails.commonInterests.length > 0 && <>
            <span className='mr-3'><Home /></span>
            {profileData.otherDetails.commonInterests.map((data) => {
                return (
                    <span className='mr-2 my-1'>
                        <Card className="px-2" style={{ background: '#c4c4c4', fontWeight: '500', fontSize: '16px' }}> {data} </Card>
                    </span>
                )
            })}
        </>}
    </div> */}