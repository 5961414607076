import React, { useContext, useState, useEffect } from 'react';
import Axios from "axios";
import { baseUrl } from "../../apiContent/api.jsx";
import Profile from "./profile";
import '../css/homePage.css';
import Publication from './publication';
import ProfileDetail from './profileDetail';
import { CircularProgress } from '@material-ui/core';
import Feed from './feed.jsx';
import Header from '../../sharedThinkly/common/header.jsx';
import Footer from '../../sharedThinkly/common/footer';
import { RemoteConfiguration } from '../../../configs/remoteConfig.js';
import DashboardPage from './dashboard.jsx';
import { isMobile } from 'react-device-detect';
import PlayStore from "../../sharedThinkly/images/playStore.svg";
import AppStore from "../../sharedThinkly/images/appstore.svg";
import { useHistory } from 'react-router-dom';
import MyStar from './myStar'
import MyDraft from './myDraft'
import { getAnalytics, logEvent } from "firebase/analytics";
import ScheduledList from './scheduleList.jsx';

const HomePage = (props) => {
    const analytics = getAnalytics();
    const BASE_URL = useContext(baseUrl);
    const history = useHistory();
    const [user_ID, setuser_ID] = useState()
    const [user_status, setuser_status] = useState()
    const [thinklyConfigData, setthinklyConfigData] = useState()
    const [value, setValue] = useState(null);
    const [getvalue, setvalue] = useState(false);
    const [profileData, setProfileData] = useState();
    const [openInAppUrl, setopenInAppUrl] = useState(null)  //dynamic link for mob
    const [Headervisible, setHeadervisible] = useState(false)
    const [supporterData, setsupporterData] = useState()
    const [UserBalance, setUserBalance] = useState()  //Stars count

    useEffect(() => {
        async function fetchData() {
            await RemoteConfiguration()
            if (props.userID !== undefined && props.userStatus !== undefined) {
                logEvent(analytics, 'HOME_EVENT', { userid: props.userID })
                setuser_ID(props.userID)
                setuser_status(props.userStatus)
                fetchUserProfileData(props.userID)
                fetchSupporterData(props.userID)
                GetMyStarBalance(props.UserID)
                window.sessionStorage.setItem("userStatus", "S")
            } else if (props.location.userID !== undefined && props.location.userStatus !== undefined) {
                logEvent(analytics, 'HOME_EVENT', { userid: props.location.userID })
                setuser_ID(props.location.userID)
                setuser_status(props.location.userStatus)
                fetchUserProfileData(props.location.userID)
                fetchSupporterData(props.location.userID)
                GetMyStarBalance(props.location.userID)
                window.sessionStorage.setItem("userStatus", "S")
            }
            if (window.sessionStorage.getItem("userStatus") === "S") {
                const id = window.sessionStorage.getItem("UserID")
                logEvent(analytics, 'HOME_EVENT', { userid: id })
                const status = window.sessionStorage.getItem("userStatus")
                setuser_ID(id)
                setuser_status(status)
                fetchUserProfileData(id)
                fetchSupporterData(id)
                GetMyStarBalance(id)
            }
            const remoteConfig = window.sessionStorage.getItem("ThinklyConfigData")
            const remoteConfigJson = JSON.parse(remoteConfig)
            setthinklyConfigData(remoteConfigJson)
        }
        fetchData()
    }, [props]);

    function fetchUserProfileData(authorID) {
        setProfileData()
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorID
            },
        };
        Axios.get(`${BASE_URL}User/GetUserProfileByID/${authorID}`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    setHeadervisible(true)
                    if (res.data.responseData.profileDetails.penName !== undefined) {
                        const link = "https://app.thinkly.me/?&apn=com.me.digicita.thinkly.dev&ibi=com.Thinkly.Thinkly&imv=10.0&isi=1329943323&link=https://test.thinkly.me/thinkly/" + res.data.responseData.profileDetails.penName
                        setopenInAppUrl(link)
                    }
                    window.sessionStorage.setItem("PublicationCount", res.data.responseData.otherDetails.totalPublicationsCount)
                    setProfileData(res.data.responseData);
                }
            })
            .catch((err) => {
                console.log("GetUserProfileByID error in catch", err);
            });
    }

    function fetchSupporterData(authorID) {
        var config = {
            headers: {
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": authorID
            },
        };
        Axios.get(`${BASE_URL}User/GetMySupportDashboard`, config)
            .then((res) => {
                setsupporterData(res.data.responseData);
            })
    }

    function GetMyStarBalance(userID) {
        var config = {
            headers: {
                "Content-Type": "application/json",
                "DeviceID": process.env.REACT_APP_DEVICE_ID,
                "UserID": userID
            },
        };
        Axios.get(`${BASE_URL}User/GetMyBalance`, config)
            .then((res) => {
                if (res.data.responseCode === '00') {
                    setUserBalance(res.data.responseData.UserBalance)
                }
            })
            .catch((err) => {
                console.log("GetSummaryDetailsByID error in catch", err);
            });
    }

    const profileDetail = (value) => {
        setvalue(true);
        setValue(value);
    }

    return (<>
        {profileData !== undefined && profileData !== null ? <div className={isMobile ? 'container' : 'container pr-5'}>
            {Headervisible && <Header publicationCount={profileData.otherDetails.totalPublicationsCount} user_profile={profileData}
                authorID={user_ID} userStatus={user_status} thinklyConfigJSON={thinklyConfigData} />}
            <div className='row'>
                {isMobile ? <div className='col-12 text-center py-4' style={{ marginTop: '5rem' }}>
                    <p className="font-weight-bold fs-20">Download the App for a richer experience! </p>
                    <p className='fs-15'>Follow your favourite publications, engage in meaningful conversations, earn & redeem Thinkly Stars for exciting rewards and many more features.</p>
                    <div className="row text-center mb-3">
                        <div className="col-6 text-right">
                            <a href={openInAppUrl}>
                                <img src={PlayStore} style={{ width: '9rem', borderRadius: '10px' }} alt="Download button for Play store" />
                            </a>
                        </div>
                        <div className="col-6 text-left">
                            <a href={openInAppUrl}>
                                <img src={AppStore} style={{ width: '8rem' }} alt="Download button for App store" />
                            </a>
                        </div>
                    </div>
                </div> : <>
                    <div className='col-8 pr-5 card-fixed' style={{ marginTop: '6rem' }}>
                        {getvalue ? <>
                            {value === 'ProfileDetail' ? <ProfileDetail />
                                : value === 'Publication' ? <Publication authorID={user_ID} profileJson={profileData} />
                                    : value === 'Thinkly' ? <Feed authorID={user_ID} profileJson={profileData} thinklyConfigJSON={thinklyConfigData} />
                                        : value === 'Dashboard' ? <DashboardPage profileJson={profileData} supporterData={supporterData} authorID={user_ID} UserBalance={UserBalance} onChangeCallback={(id) => GetMyStarBalance(id)} onChangeCallback1={(id) => fetchUserProfileData(id)} />
                                            : value === 'Stars' ? <MyStar authorID={user_ID} UserBalance={UserBalance} onChangeCallback={(id) => GetMyStarBalance(id)} onChangeCallback1={(id) => fetchUserProfileData(id)} />
                                                : value === 'Drafts' ? <MyDraft authorID={user_ID} thinklyConfigJSON={thinklyConfigData} />
                                                    : value === 'Scheduled' ? <ScheduledList authorID={user_ID} thinklyConfigJSON={thinklyConfigData} /> : ''}

                        </> : <>
                            {profileData.profileDetails.isPartialProfile === false && profileData.profileDetails.isSupportEnabled === false ? <Publication authorID={user_ID} profileJson={profileData} /> :
                                profileData.profileDetails.isPartialProfile === false && profileData.profileDetails.isSupportEnabled === true && <DashboardPage profileJson={profileData} supporterData={supporterData} authorID={user_ID} UserBalance={UserBalance} onChangeCallback={(id) => GetMyStarBalance(id)} onChangeCallback1={(id) => fetchUserProfileData(id)} />}
                        </>}
                    </div>
                    <div style={{ background: 'lightgray', height: 'auto', width: '1px', marginRight: '-40px', marginLeft: '38px' }}></div>
                    <div className='col-1'></div>
                    <div className='col-3 card-fixed'>
                        <Profile profileDetail={(setValue) => profileDetail(setValue)} profileJson={profileData} userStatus={user_status} supporterData={supporterData} UserBalance={UserBalance} />
                    </div>
                </>}

            </div>
        </div> : <div style={{ padding: '150px 0px', textAlign: 'center' }}>
            <CircularProgress aria-label="Loading..." />
        </div>}
        <Footer />
    </>)
}

export default HomePage
